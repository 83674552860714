import { npubEncode } from "nostr-tools/nip19";
import { generateSecretKey, getPublicKey } from "nostr-tools/pure";
import { bytesToHex, hexToBytes } from "@noble/hashes/utils";
import { NostrIdUnsafe } from "@bitsacco/types";

import { BS_API_GATEWAY_URL } from "../configs";
import {
  setLocalValue,
  BITSACCCO_NOSTR_PRIVATE_KEY,
  getLocalValue,
} from "./storage";

export const createNewNostrId = async (): Promise<NostrIdUnsafe> => {
  const sec = generateSecretKey();
  const pub = getPublicKey(sec);

  // Persist the secret key in local storage
  const hsec = bytesToHex(sec);
  setLocalValue(BITSACCCO_NOSTR_PRIVATE_KEY, hsec);

  return {
    hpub: pub,
    hsec,
  };
};

export const getNostrIdFromLocal = async (): Promise<NostrIdUnsafe> => {
  const hsec = getLocalValue(BITSACCCO_NOSTR_PRIVATE_KEY);

  if (hsec) {
    const sec = hexToBytes(hsec);
    const hpub = getPublicKey(sec);
    const npub = npubEncode(hpub);

    return {
      hpub,
      npub,
      hsec,
    };
  }

  return {};
};

export const sendNostrDM = async (message: string, npub?: string) => {
  try {
    const payload = {
      message,
      recipient: {
        npub,
      },
    };

    await fetch(`${BS_API_GATEWAY_URL}/nostr/dm`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Origin: `https://0.0.0.0`,
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (!response.ok) {
        throw new Error(
          `${response.status}: error sending nostr dm: ${response.statusText}`,
        );
      }
      return;
    });
  } catch (error) {
    console.error(error);
  }
};
