import React, { useCallback } from "react";
import {
  Chama,
  ChamaMember,
  ChamaMemberRole,
  UpdateChama,
} from "@bitsacco/types";
import {
  useDisclosure,
  Flex,
  Heading,
  Center,
  Divider,
  Button,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import { getUserBalanceMsats, getGroupBalanceMsats } from "../../services";
import { TOAST_TIMEOUT_MS } from "../../configs";
import { useApi, useQuote } from "../Providers";
import { MemberInfo } from "./ChamaInfo";
import { InviteModal } from "../modal";
import { ComingSoon } from "../ComingSoon";
import { ChamaViewProps } from "./types";
import { btcToFiat } from "@bitsacco/types/dist/fx";
import { formatNumber } from "../../utils";

export const ChamaCardMain = React.memo(function ChamaCardMain({
  user,
  chama,
  isAdmin,
}: ChamaViewProps) {
  const { bitsacco } = useApi();
  const quote = useQuote();
  const toast = useToast();

  const {
    isOpen: showInviteModal,
    onOpen: onOpenInviteModal,
    onClose: onCloseInviteModal,
  } = useDisclosure();

  const showBalanceInSats = useCallback((amountMsats: number) => {
    return (
      <Heading size="md" fontWeight="bold">
        &#8383; {formatNumber(amountMsats / 1000)} SATS
      </Heading>
    );
  }, []);

  const showBalanceInFiat = useCallback(
    (amountMsats: number) => {
      if (!quote) {
        return <></>;
      }
      const { amountFiat } = btcToFiat({
        amountMsats,
        fiatToBtcRate: Number(quote!.rate),
      });

      return (
        <Text pt="2">
          &#61; <strong>{formatNumber(amountFiat)}</strong> KES
        </Text>
      );
    },
    [quote],
  );

  const renderUserBalance = useCallback(() => {
    const amountMsats = getUserBalanceMsats(chama.members, user.id);

    return (
      <Flex direction="column" flexGrow={1} w={{ base: "100%", md: "auto" }}>
        <Heading size="xs" pb="2">
          My Funds
        </Heading>
        {showBalanceInSats(amountMsats)}
        {showBalanceInFiat(amountMsats)}
      </Flex>
    );
  }, [user, chama]);

  const renderGroupBalance = useCallback(() => {
    const amountMsats = getGroupBalanceMsats(chama.members);

    return (
      <Flex direction="column" flexGrow={1} w={{ base: "100%", md: "auto" }}>
        <Heading size="xs" pb="2">
          Group Funds
        </Heading>
        {showBalanceInSats(amountMsats)}
        {showBalanceInFiat(amountMsats)}
      </Flex>
    );
  }, [chama]);

  const updateChama = useCallback(
    (payload: UpdateChama, context: string) => {
      try {
        (async () => {
          const updated = await bitsacco.request<Chama, UpdateChama>(
            "PATCH",
            "/chama/update",
            payload,
          );

          if (updated) {
            toast({
              title: "Success",
              description: `Successfully ${context}`,
              status: "success",
              duration: TOAST_TIMEOUT_MS,
              isClosable: true,
            });
          } else {
            toast({
              title: "Error",
              description: `Failed to ${context}`,
              status: "error",
              duration: TOAST_TIMEOUT_MS,
              isClosable: true,
            });
          }
        })();
      } catch (e) {
        console.error(e);
        toast({
          title: "Error",
          description: "Error when updating chama",
          status: "error",
          duration: TOAST_TIMEOUT_MS,
          isClosable: true,
        });
      }
    },
    [bitsacco, toast],
  );

  const configureAdmin = useCallback(
    (member: ChamaMember) => {
      updateChama(
        { id: chama.id, updates: { members: { [member.id]: member } } },
        "reconfigured chama admins",
      );
    },
    [chama, updateChama],
  );

  const removeMember = useCallback(() => {
    console.log("removeMember");
  }, []);

  const memberSort = Object.entries(chama.members).sort(([, a], [, b]) =>
    a.role === ChamaMemberRole.Admin
      ? -1
      : b.role === ChamaMemberRole.Admin
        ? 1
        : 0,
  );
  const adminCount = memberSort.filter(
    ([, member]) => member.role === ChamaMemberRole.Admin,
  ).length;

  return (
    <>
      <Flex direction="column" gap={4}>
        <Flex
          flexDirection="column"
          px={{ base: "", md: "3" }}
          gap={{ base: "6", lg: "6" }}
        >
          <Heading size="sm" textTransform="uppercase">
            Funds
          </Heading>
          <Flex
            display="flex"
            flexDirection="row"
            flexWrap={{ base: "wrap", lg: "nowrap" }}
            justifyContent="space-between"
            px={{ base: "", md: "3" }}
            gap={{ base: "6", lg: "6" }}
          >
            {renderGroupBalance()}
            <Center height={{ base: "0%", sm: "80px" }}>
              <Divider orientation="vertical" />
            </Center>
            {renderUserBalance()}
          </Flex>
        </Flex>

        <Divider orientation="horizontal" />

        <Flex direction="column" gap={4} flexGrow={1}>
          <Flex
            gap={"4"}
            direction="row"
            flexWrap={{ base: "wrap", lg: "nowrap" }}
            align="center"
            alignItems={"center"}
            justifyItems={"center"}
            justify="space-between"
          >
            <Heading size="sm" textTransform="uppercase">
              Members
            </Heading>
            {isAdmin && (
              <Button
                variant="outline"
                colorScheme="green"
                height={"35px"}
                onClick={onOpenInviteModal}
              >
                + invite
              </Button>
            )}
          </Flex>

          <Flex pt={2} flexDirection="row" flexWrap={{ base: "wrap" }} gap={2}>
            {memberSort.map(([idx, member]) => {
              return (
                <MemberInfo
                  key={idx}
                  member={member}
                  user={user}
                  showMenu={isAdmin}
                  adminCount={adminCount}
                  configureAdmin={configureAdmin}
                  removeMember={removeMember}
                />
              );
            })}
          </Flex>
          <Divider orientation="horizontal" />
          <Heading size="sm" textTransform="uppercase">
            Rules
          </Heading>
          <UnorderedList gap={2}>
            <ComingSoon />
          </UnorderedList>
        </Flex>
      </Flex>
      <InviteModal
        chama={chama}
        isOpen={showInviteModal}
        onClose={onCloseInviteModal}
      />
    </>
  );
});
