import { useState, useEffect } from "react";
import { UseToastOptions } from "@chakra-ui/react";
import { parsePhoneNumber } from "awesome-phonenumber";
import {
  SESSION_COOKIE_KEY,
  SESSION_HEADER_KEY,
  Chama,
  ChamaMemberRole,
  ChamaMembers,
  User,
} from "@bitsacco/types";
import { PhoneRegionCode, TOAST_TIMEOUT_MS } from "./configs";

import {
  getSessionValue,
  setSessionValue,
  SESSION_USER_KEY,
  removeSessionValue,
} from "./services/storage";

export const fetcher = async <T, B>(
  url: string,
  method: "GET" | "POST" | "PUT" | "PATCH" = "GET",
  body?: B,
): Promise<T> => {
  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getSessionValue(SESSION_COOKIE_KEY)}`,
      Origin: `https://0.0.0.0`,
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const res = await fetch(url, options);

  // Handle session creation paths
  const SessionCreatePaths = ["/user/login", "/user/register", "/user/recover"];
  if (SessionCreatePaths.includes(url)) {
    const sessionId = res.headers.get(SESSION_HEADER_KEY);
    sessionId && setSessionValue(SESSION_COOKIE_KEY, sessionId);
  }

  if (!res.ok) {
    if (res.status === 401) {
      removeSessionValue(SESSION_COOKIE_KEY);
      removeSessionValue(SESSION_USER_KEY);
      throw new Error("Unauthorized");
    }
    throw new Error(await res.text());
  }

  return res.json();
};

export const formatNumber = (number: number, currency?: string): string => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);
};

export const truncateText = (
  text: string,
  fromStart: number,
  fromEnd: number,
): string => {
  if (!fromStart && !fromEnd) {
    return text;
  }

  if (text.length > fromStart + fromEnd) {
    return text.slice(0, fromStart) + "..." + text.slice(-fromEnd);
  }

  if (text.length > fromStart) {
    return text.slice(0, fromStart) + "... ";
  }

  return text;
};

export const copyToClipboard = (text: string): Promise<void> => {
  if (!navigator.clipboard) {
    return Promise.reject("Clipboard API is not available.");
  }

  return navigator.clipboard.writeText(text);
};

/**
 * Removes any non-digit characters from a phone number
 * @param num original phone number string, possibly includes non-digit characters
 * @returns phone number string with only digits
 */
export const digitizePhone = (
  num: string,
  noplus = false,
  nospace = true,
): string => {
  if (noplus) {
    num = num.replace(/\+/g, "");
  }

  if (nospace) {
    num = num.replace(/\s/g, "");
  }

  return num;
};

/**
 * Selects a readable label from user profile or other base data
 * @param user User data
 * @returns string
 */
export const getProfileLabel = (user: User): string => {
  return user.profile?.name || user.phone || "";
};

// Regex validator for 6 digit pins and OTP
const r = /^\d{6}$/;

export const isValidPin = (pin: string): boolean => {
  return r.test(pin);
};

export const isValidOTP = (otp: string): boolean => {
  return r.test(otp);
};

export const isValidPhone = (phone: string): boolean => {
  return parsePhoneNumber(phone).valid;
};

export const isKenyanPhone = (phone: string): boolean => {
  const num = parsePhoneNumber(phone);
  return num.valid && num.regionCode === PhoneRegionCode.Kenya;
};

export const isValidAddress = (address?: string): boolean => {
  if (!address) {
    return false;
  }

  const [name, domain] = address.split("@");
  return name!.length > 0 && domain === "bitsacco.com";
};

export const useDebounce = function <T>(
  value: T,
  delay: number,
): [debounced: T, setDebounced: (value: T) => void] {
  const [debounced, setDebounced] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounced(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [debounced, setDebounced];
};

export const cancellablePromise = function <T>(promise: Promise<T>) {
  const isCancelled = { value: false };

  const wrappedPromise = new Promise<T>((res, rej) => {
    promise
      .then((d) => {
        return isCancelled.value ? rej(isCancelled) : res(d);
      })
      .catch((e) => {
        rej(isCancelled.value ? isCancelled : e);
      });
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled.value = true;
    },
  };
};

export const createCopyTextFn = (toast: (options: UseToastOptions) => void) => {
  return (name: string, value: string) => {
    (async () => {
      try {
        await copyToClipboard(value);
        toast({
          title: "Success",
          description: `Copied ${name} to clipboard.`,
          status: "success",
          duration: TOAST_TIMEOUT_MS,
          isClosable: true,
        });
      } catch (e) {
        console.error(e);
      }
    })();
  };
};

export const isChamaAdmin = (chamaMembers: ChamaMembers, userId: string) => {
  const member = chamaMembers[userId];
  return member !== undefined && member.role === ChamaMemberRole.Admin;
};

export const adminCount = (chamaMembers: ChamaMembers) => {
  return Object.values(chamaMembers).filter(
    (member) => member.role === ChamaMemberRole.Admin,
  ).length;
};

export const getAdminRecommendation = (
  chamaMembers: ChamaMembers,
  adminCount: number,
): number => {
  const memberCount = Object.keys(chamaMembers).length;
  const rc = Math.min(Math.max(3, Math.ceil(memberCount * 0.21)), 5);

  if (memberCount === adminCount || rc === adminCount) {
    return -1;
  }

  if (memberCount < 4) {
    return 2;
  }

  if (memberCount < 7 && adminCount < 3) {
    return 3;
  }

  return rc;
};

export const isChamaTarget = (
  target: Chama | { id: string; name: string },
): target is Chama => {
  return "members" in target;
};

export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true;
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  )
    return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
};

export const isValidNpub = (value: string): boolean => {
  const npubRegex = /^npub1[0-9a-z]{58}$/;
  return npubRegex.test(value);
};
