"use strict";
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.7
//   protoc               v3.21.12
// source: lib.proto
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionStatus = void 0;
/* eslint-disable */
/** TransactionStatus: Enum representing the possible statuses of a transaction. */
var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus[TransactionStatus["PENDING"] = 0] = "PENDING";
    TransactionStatus[TransactionStatus["PROCESSING"] = 1] = "PROCESSING";
    TransactionStatus[TransactionStatus["FAILED"] = 2] = "FAILED";
    TransactionStatus[TransactionStatus["COMPLETE"] = 3] = "COMPLETE";
    TransactionStatus[TransactionStatus["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(TransactionStatus || (exports.TransactionStatus = TransactionStatus = {}));
