import React from "react";
import {
  ChamaTx,
  ChamaMember,
  ChamaTxReview,
  ChamaTxState,
} from "@bitsacco/types";
import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import { FaEllipsisVertical } from "react-icons/fa6";
import { deepEqual } from "../../utils";

interface ActivityMenuProps {
  tx: ChamaTx;
  member: ChamaMember;
  isAdmin: boolean;
  copyTxId: () => void;
  reviewTx: (review: ChamaTxReview) => void;
  cancelTx: () => void;
  withdraw?: () => void;
}

export const ActivityMenu = React.memo(function ActivityMenu({
  tx,
  member,
  isAdmin,
  cancelTx,
  copyTxId,
  reviewTx,
  withdraw,
}: ActivityMenuProps) {
  return (
    <Menu isLazy>
      <MenuButton
        variant="ghost"
        as={IconButton}
        aria-label="Options"
        icon={<FaEllipsisVertical />}
        size="sm"
        ml={2}
      />
      <MenuList>
        {showApproveMenu(tx, member, isAdmin) && (
          <MenuItem onClick={() => reviewTx(ChamaTxReview.Approve)}>
            Approve
          </MenuItem>
        )}
        {showRejectMenu(tx, member, isAdmin) && (
          <MenuItem onClick={() => reviewTx(ChamaTxReview.Reject)}>
            Reject
          </MenuItem>
        )}
        {showCancelMenu(tx, member) && (
          <MenuItem onClick={cancelTx}>Cancel</MenuItem>
        )}
        <MenuItem onClick={copyTxId}>Copy ID</MenuItem>
        {withdraw && <MenuItem onClick={withdraw}>Withdraw</MenuItem>}
      </MenuList>
    </Menu>
  );
});

const showApproveMenu = (
  tx: ChamaTx,
  member: ChamaMember,
  isAdmin: boolean,
) => {
  return (
    isAdmin && // user is admin
    tx.meta.user !== member.id && // transaction is not theirs
    (tx.state === ChamaTxState.Pending || tx.state === ChamaTxState.Rejected) && // transaction is pending or rejected
    !hasApprovedTx(tx, member) // they have not yet approved it
  );
};

const showRejectMenu = (tx: ChamaTx, member: ChamaMember, isAdmin: boolean) => {
  return (
    isAdmin && // user is admin
    tx.meta.user !== member.id && // transaction is not theirs
    (tx.state === ChamaTxState.Pending || tx.state === ChamaTxState.Approved) && // transaction is pending or approved
    !hasRejectedTx(tx, member) // they have not yet rejected it
  );
};

const hasApprovedTx = (tx: ChamaTx, member: ChamaMember) => {
  return tx.approvals.find((approval) => deepEqual(approval, member));
};

const hasRejectedTx = (tx: ChamaTx, member: ChamaMember) => {
  return tx.rejections.includes({ ...member });
};

const showCancelMenu = (tx: ChamaTx, member: ChamaMember) => {
  return (
    tx.meta.user === member.id && // transaction is theirs
    (tx.state === ChamaTxState.Pending || tx.state === ChamaTxState.Approved) // transaction is pending or approved
  );
};
