import React from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Text, Button, Center, Icon } from "@chakra-ui/react";
import { Routes } from "../routes";

interface FeatureTeaseProps {
  img?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  title: string;
}

export const FeatureTease = React.memo(function FeatureTease({
  img,
  title,
}: FeatureTeaseProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <Flex
      flexDir={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      border={"2px solid teal"}
      gap={3}
      p={10}
    >
      <Text fontSize={"20px"} fontWeight={"bold"} textAlign={"center"}>
        {title}
      </Text>
      {img && (
        <Center h={"220px"} w={"220px"}>
          <Center h={"210px"} w={"210px"} overflow={"hidden"}>
            <Icon as={img} height={"100%"} width={"100%"} />
          </Center>
        </Center>
      )}
      <Text textAlign={"center"} pb={6}>
        {
          "To access this feature, you need to be a fully registered member with one (1) or more shares."
        }
      </Text>
      <Button
        height={"45px"}
        minW={"150px"}
        colorScheme="teal"
        onClick={() => navigate(Routes.Membership)}
      >
        View Membership
      </Button>
    </Flex>
  );
});
