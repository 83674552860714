import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Flex,
  Text,
  useToast,
  Progress,
  Icon,
  useDisclosure,
  Card,
  CardBody,
  Heading,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
} from "@chakra-ui/react";
import { FaInfoCircle } from "react-icons/fa";
import { ShareDetailResponse } from "@bitsacco/types";
import {
  MembershipInfoModal,
  ShareInputGroup,
  TableDisplay,
  TableMenu,
  TemplateModal,
  useAuth,
} from "../components";
import { BS_API_GATEWAY_URL, BS_INTASEND_PUBKEY } from "../configs";
import { fetcher, formatNumber } from "../utils";

interface ShareActivity {
  type: string;
  quantity: number;
  date: string;
}

const BITSACCO_SHARE_VALUE_KES = 1000;

export const SaccoMembership = () => {
  const { user } = useAuth();
  const [shareHoldings, setShareHoldings] = useState<number>(0);
  const [shareOrder, setShareOrder] = useState<number>(0);
  const [shareActivity, setShareActivity] = useState<ShareActivity[]>([]);
  const toast = useToast();

  const [sharesIssued, setSharesIssued] = useState<number>(0);
  const [sharesSold, setSharesSold] = useState<number>(0);

  const {
    isOpen: showMembershipInfoModal,
    onOpen: onOpenMembershipInfoModal,
    onClose: onCloseMembershipInfoModal,
  } = useDisclosure();

  const [isOptionsModalOpen, setOptionsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const updateState = (response: ShareDetailResponse) => {
    if (response.shares) {
      setShareHoldings(response.shareHoldings);

      const formattedShares = response.shares.map(
        (share: ShareDetailResponse["shares"][0]) => ({
          type: "Subscribe",
          quantity: share.quantity,
          date: new Date(
            Number(
              (BigInt(share.purchasedAtUnix.high) << BigInt(32)) |
                BigInt(share.purchasedAtUnix.low),
            ),
          ).toLocaleDateString(),
        }),
      );
      setShareActivity(formattedShares);
    }

    if (response.shareSubscription) {
      setSharesIssued(response.shareSubscription.sharesIssued);
      setSharesSold(response.shareSubscription.sharesSold);
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://unpkg.com/intasend-inlinejs-sdk@4.0.7/build/intasend-inline.js";
    script.async = false;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!user) return;

    (async () => {
      try {
        const response = await fetcher<ShareDetailResponse, undefined>(
          `${BS_API_GATEWAY_URL}/shares/detail?user=${user.id}`,
          "GET",
        );

        updateState(response);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [user, setSharesIssued, setSharesSold]);

  const handlePayButtonClick = useCallback(() => {
    if (!user || !window.IntaSend) {
      return;
    }

    const buyShares = () => {
      (async () => {
        if (shareOrder <= 0 || !user) return;

        try {
          const response = await fetcher<
            ShareDetailResponse,
            { quantity: number; userId: string }
          >(`${BS_API_GATEWAY_URL}/shares/buy`, "POST", {
            userId: user.id,
            quantity: shareOrder,
          });
          updateState(response);
          toast({
            title: "Success",
            description: "Shares purchased successfully",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          setShareOrder(0);
        } catch (error) {
          console.error(error);
          toast({
            title: "Error",
            description:
              "Failed to purchase shares. Please contact Bitsacco Admin",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      })();
    };

    const intasend = new window.IntaSend({
      publicAPIKey: BS_INTASEND_PUBKEY,
      live: !BS_INTASEND_PUBKEY.includes("test"),
    });

    intasend.on("COMPLETE", (results: unknown) => {
      console.log("Payment successful:", results);
      buyShares();
    });
    intasend.on("FAILED", (error: unknown) => {
      console.error("Payment failed:", error);
      toast({
        title: "Error",
        description: "Failed to purchase shares",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    });
    intasend.on("IN-PROGESS", (progress: unknown) => {
      console.log("Payment progress", progress);
    });

    const payload = {
      public_key: BS_INTASEND_PUBKEY,
      currency: "KES",
      amount: shareOrder * BITSACCO_SHARE_VALUE_KES,
      reference: "Buy Bitsacco Shares",
      description: "Buy Bitsacco Shares",
    };

    intasend.run(payload);
  }, [
    user,
    shareOrder,
    setShareOrder,
    setShareHoldings,
    setShareActivity,
    toast,
  ]);

  const handleOptionSelect = async (option: string) => {
    if (option === "Copy ID") {
      const userId = user?.id || "";
      await navigator.clipboard.writeText(userId);
      toast({
        title: "Copied successfully!",
        description: `User ID ${userId} copied to clipboard.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    setOptionsModalOpen(false);
  };

  const totalPages = Math.ceil(shareActivity.length / itemsPerPage);
  const paginatedShareActivity = shareActivity.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage,
  );

  return (
    <>
      <Flex direction="column" flexGrow={1} basis="100%" gap="5" p="5" pb="20">
        <Flex
          p="5"
          gap="4"
          direction="row"
          flexWrap={{ base: "wrap", lg: "nowrap" }}
          align="center"
          justifyItems="center"
          justify="space-between"
        >
          <Flex
            direction="row"
            flexWrap={{ base: "wrap", lg: "nowrap" }}
            justify={{ base: "start", lg: "center" }}
            align={{ base: "start", lg: "center" }}
            gap={2}
          >
            <Heading size="md">MEMBERSHIP</Heading>
            <Text>Subscribe, track and manage your Bitsacco member shares</Text>
          </Flex>
          <Button
            variant="outline"
            colorScheme="green"
            height="35px"
            onClick={onOpenMembershipInfoModal}
          >
            about membership
            <Icon as={FaInfoCircle} ml="2" />
          </Button>
        </Flex>

        <Flex
          display={"flex"}
          alignItems={"center"}
          justifyItems={"center"}
          justifyContent={"center"}
          w={"100%"}
        >
          <Card w={{ base: "600px", xl: "800px" }} h={"350px"}>
            <CardBody
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyItems={"center"}
              justifyContent={"center"}
              gap={3}
            >
              <Text>you own</Text>
              <Heading
                fontSize={{ base: "30px", md: "48px" }}
                fontWeight="bold"
              >
                {shareHoldings} SHARES
              </Heading>
              <Text fontSize={{ base: "24px", lg: "30px" }} fontWeight="bold">
                &#61; {formatNumber(shareHoldings * BITSACCO_SHARE_VALUE_KES)}{" "}
                KES
              </Text>

              <Flex
                direction="row"
                flexWrap={{ base: "wrap", lg: "nowrap" }}
                alignItems={"center"}
                justifyItems={"center"}
                justifyContent={"center"}
                gap={5}
                pt={4}
              >
                <ShareInputGroup
                  shares={shareOrder}
                  setShare={setShareOrder}
                  getFormHelperText={() => <></>}
                />
                <Button
                  colorScheme="teal"
                  isDisabled={shareOrder <= 0}
                  onClick={handlePayButtonClick}
                  height={"45px"}
                  minW={"150px"}
                  flexGrow={1}
                >
                  Subscribe Shares
                </Button>
              </Flex>
            </CardBody>
          </Card>
        </Flex>

        <Flex direction="column" my="12" gap={2}>
          <Text mb="2">
            <strong>BITSACCO BETA</strong> SHARE SUBSCRIPTIONS
          </Text>
          <Progress
            value={(sharesSold / sharesIssued) * 100}
            size="md"
            colorScheme="green"
            borderRadius="sm"
          />
          <Flex justify="space-between" pt={2}>
            <Text mb="2">{sharesSold} subscribed</Text>
            <Text mb="2">{sharesIssued} available</Text>
          </Flex>
        </Flex>

        <Flex direction="column">
          <Text mb="4">SHARE HISTORY</Text>
          <Flex direction="column">
            <Table variant={"striped"}>
              <Thead>
                <Tr>
                  <Th>Action</Th>
                  <Th>Shares</Th>
                  <Th>Date</Th>
                  <Th>{""}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedShareActivity.map((record, index) => (
                  <TableDisplay
                    key={index}
                    action={record.type}
                    shares={record.quantity}
                    dateHappened={record.date}
                    moreActions={
                      <TableMenu
                        onCopyId={() => handleOptionSelect("Copy ID")}
                      />
                    }
                  />
                ))}
              </Tbody>
            </Table>
            <Flex justify="space-between" mt="4">
              <Button
                onClick={() => setCurrentPage(currentPage - 1)}
                isDisabled={currentPage === 0}
              >
                previous
              </Button>
              <Text>
                page {currentPage + 1} of {totalPages}
              </Text>
              <Button
                onClick={() => setCurrentPage(currentPage + 1)}
                isDisabled={currentPage >= totalPages - 1}
              >
                next
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <MembershipInfoModal
        isOpen={showMembershipInfoModal}
        onClose={onCloseMembershipInfoModal}
      />
      <TemplateModal
        isOpen={isOptionsModalOpen}
        onClose={() => setOptionsModalOpen(false)}
        header={<Text>Select an Option</Text>}
        body={
          <Flex direction="column">
            <Button
              colorScheme="teal"
              onClick={() => {
                handleOptionSelect("Copy ID");
                setOptionsModalOpen(false);
              }}
            >
              Copy ID
            </Button>
          </Flex>
        }
        footer={
          <Button colorScheme="red" onClick={() => setOptionsModalOpen(false)}>
            Close
          </Button>
        }
      />
    </>
  );
};
