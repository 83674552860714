import React, { useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { TemplateModal } from "./TemplateModal";

interface PersonalSavingsInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PersonalSavingsInfoModal = React.memo(
  function PersonalSavingsInfoModal({
    isOpen,
    onClose,
  }: PersonalSavingsInfoModalProps): JSX.Element {
    const getModalBody = useCallback(() => {
      return (
        <Flex flexDirection="column" gap="5">
          <Text>
            Every fully registered Bitsacco have access to a Personal Savings
            wallet for Bitcoin. The member can:
          </Text>
          <UnorderedList pl={4} spacing={3}>
            <ListItem>
              Instantly deposit funds either from KES or as Bitcoin
            </ListItem>
            <ListItem>Instantly withdraw funds to KES or as Bitcoin</ListItem>
            <ListItem>
              All funds can be withdrawn, with NO lock up period or restrictions
            </ListItem>
            <ListItem>
              There is no limit to the amount of funds that can be transacted
              with this account
            </ListItem>
          </UnorderedList>
          <Text>
            <strong>
              All personal savings are held as Bitcoin within Bitsacco
            </strong>
          </Text>
        </Flex>
      );
    }, []);

    return (
      <TemplateModal
        isOpen={isOpen}
        onClose={onClose}
        header={<Text>About Bitsacco Personal Savings</Text>}
        body={getModalBody()}
        footer={
          <Button onClick={onClose} variant="outline" colorScheme="teal">
            I acknowledge and understand
          </Button>
        }
      />
    );
  },
);
