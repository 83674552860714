import React, { useCallback, useEffect, useState } from "react";
import {
  ChamaTxType,
  ChamaTxState,
  ChamaTx,
  ChamaTxPath,
  CreateChamaTx,
  ChamaTxReviewer,
  TxPath,
  TransactionType,
} from "@bitsacco/types";

import { BS_API_URL } from "../../configs";
import { fetcher, isChamaAdmin, isChamaTarget } from "../../utils";
import { ActiveTx, TransactionTarget } from "../transactions";
import {
  ChamaToTransactionState,
  ChamaToTransactionType,
} from "../TransactionState";
import { TransactionModal } from "./TransactionModal";

interface ChamaSavingsDepositModalProps {
  depositTarget: TransactionTarget;
  isOpen: boolean;
  onClose: () => void;
}

export const ChamaSavingsDepositModal = React.memo(
  function ChamaSavingsDepositModal({
    depositTarget,
    isOpen,
    onClose,
  }: ChamaSavingsDepositModalProps): JSX.Element {
    const [preApprovers, setPreApprovers] = useState<ChamaTxReviewer[]>([]);

    useEffect(() => {
      const { target, user } = depositTarget;
      if (isChamaTarget(target) && isChamaAdmin(target.members, user.id)) {
        const member = target.members[user.id]!;
        setPreApprovers([
          {
            id: member.id,
            role: member.role,
          },
        ]);
      }
    }, [depositTarget]);

    const createTx = useCallback(
      (path: TxPath, amount: number) => {
        return (async (): Promise<ActiveTx> => {
          try {
            const tx = await fetcher<ChamaTx, CreateChamaTx>(
              `${BS_API_URL}/chama/tx/create`,
              "POST",
              {
                type: ChamaTxType.Deposit,
                path: path as unknown as ChamaTxPath,
                amount,
                lightning: { invoice: "", operationId: "" },
                meta: {
                  chama: depositTarget.target.id,
                  user: depositTarget.user.id,
                  description: `Deposit ${amount} KES to ${depositTarget.target.name}`,
                  timestamp: new Date(),
                },
                approvals: preApprovers,
                state: ChamaTxState.Pending,
              },
            );

            return {
              path,
              id: tx.id,
              lightning: tx.lightning,
              type: ChamaToTransactionType(tx.type),
              state: ChamaToTransactionState(tx.state),
            };
          } catch (e) {
            throw e;
          }
        })();
      },
      [depositTarget, preApprovers],
    );

    const findTx = async (activeTx: ActiveTx) => {
      try {
        const tx = await fetcher<ChamaTx, { id: string }>(
          `${BS_API_URL}/chama/tx/find`,
          "POST",
          {
            id: activeTx.id,
          },
        );

        return {
          ...activeTx,
          state: ChamaToTransactionState(tx.state),
        };
      } catch (e) {
        throw e;
      }
    };

    return (
      <TransactionModal
        isOpen={isOpen}
        onClose={onClose}
        createTx={createTx}
        findTx={findTx}
        txTarget={depositTarget}
        txType={TransactionType.DEPOSIT}
      />
    );
  },
);
