import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Center,
  Flex,
  FormControl,
  Input,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  Text,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import {
  Chama,
  ChamaMembers,
  ChamaMemberRole,
  ChamaRules,
  CreateChama,
  User,
} from "@bitsacco/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BS_API_URL, TOAST_TIMEOUT_MS } from "../../configs";
import { QueryKeys } from "../../enums/QueryKeys";
import { digitizePhone, fetcher } from "../../utils";
import { TagPhoneInputGroup } from "../InputGroups";
import { ComingSoon } from "../ComingSoon";
import { TemplateModal } from "./TemplateModal";

interface CreateChamaModalProps {
  user: User;
  chama?: Chama;
  view?: CreateChamaModalView;
  isOpen: boolean;
  onClose: (chama?: Chama) => void;
}

enum CreateChamaModalView {
  NameChama,
  DescribeChama,
  ConfigureChama,
  InviteFolks,
}

const chamaSteps: CreateChamaModalView[] = [
  CreateChamaModalView.NameChama,
  CreateChamaModalView.DescribeChama,
  CreateChamaModalView.ConfigureChama,
  CreateChamaModalView.InviteFolks,
];

export const CreateChamaModal = React.memo(function CreateChamaModal({
  user,
  chama,
  view,
  isOpen,
  onClose,
}: CreateChamaModalProps): JSX.Element {
  const toast = useToast();
  const queryClient = useQueryClient();

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [rules] = useState<ChamaRules>({});
  const [members, setMembers] = useState<ChamaMembers>({});
  const [invites, setInvites] = useState<string[]>([]);
  const [modalView, setModalView] = useState<CreateChamaModalView>(
    CreateChamaModalView.NameChama,
  );

  useEffect(() => {
    if (chama && view) {
      setName(chama.name);
      setDescription(chama.description);
      setMembers(chama.members);
      setInvites([]);
      setModalView(CreateChamaModalView.NameChama);
    }
  }, [chama, view]);

  const closeModal = useCallback(
    (chama?: Chama) => {
      setName("");
      setDescription("");
      setModalView(CreateChamaModalView.NameChama);
      onClose(chama);
    },
    [onClose],
  );

  const createChamaMutation = useMutation<Chama, Error, CreateChama>({
    mutationFn: (newChama) =>
      fetcher(`${BS_API_URL}/chama/create`, "POST", newChama),
    onSuccess: (data: Chama) => {
      queryClient.refetchQueries({
        queryKey: [QueryKeys.CHAMAS],
      });
      toast({
        title: "Success",
        description: "Chama has been created",
        status: "success",
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
      closeModal(data);
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to create chama",
        status: "error",
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
    },
  });

  const createChama = useCallback(() => {
    if (!user) {
      toast({
        title: "Error",
        description: "You must be logged in to create a Chama",
        status: "error",
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
      return;
    }

    if (!name) {
      toast({
        title: "Warning",
        description: "Please name your Chama before proceeding",
        status: "warning",
        duration: TOAST_TIMEOUT_MS,
        isClosable: true,
      });
      setModalView(CreateChamaModalView.NameChama);
      return;
    }

    members[user.id] = {
      id: user.id,
      role: ChamaMemberRole.Admin,
      contributions: 0,
      withdrawals: 0,
      penalties: 0,
    };

    const chamaData: CreateChama = {
      name,
      description,
      members,
      invites: invites.map((phone) => digitizePhone(phone)),
      rules,
    };

    createChamaMutation.mutate(chamaData);
  }, [
    createChamaMutation,
    user,
    name,
    description,
    members,
    invites,
    rules,
    toast,
  ]);

  const getModalBody = useCallback(() => {
    const modalBody = () => {
      switch (modalView) {
        case CreateChamaModalView.NameChama:
          return (
            <>
              <Text fontSize="xl" textAlign="center">
                Name your Chama
              </Text>
              <Text fontSize="md" textAlign="center" color="teal.500">
                Call it something people will recognize
              </Text>
              <FormControl>
                <Input
                  placeholder="e.g Pamoja Savings"
                  textAlign="center"
                  variant="flushed"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  mb="4"
                />
              </FormControl>
            </>
          );
        case CreateChamaModalView.DescribeChama:
          return (
            <>
              <Text fontSize="xl" textAlign="center">
                Describe your Chama
              </Text>
              <Text fontSize="md" textAlign="center" color="teal.500">
                Describe the goal of your chama
              </Text>
              <FormControl>
                <Input
                  placeholder="e.g We save together ndio tujijenge"
                  textAlign="center"
                  variant="flushed"
                  mb="4"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>
            </>
          );
        case CreateChamaModalView.ConfigureChama:
          return (
            <>
              <Text fontSize="xl" textAlign="center">
                Configure your Chama
              </Text>
              <FormControl>
                <Text fontSize="md" textAlign="center" color="teal.500">
                  Use one of the known templates to configure your chama
                </Text>
                <Center my={4}>
                  <ComingSoon />
                </Center>
                <Text fontSize="md" textAlign="center" color="teal.500">
                  Just continue for now
                </Text>
              </FormControl>
            </>
          );
        case CreateChamaModalView.InviteFolks:
          return (
            <>
              <Text fontSize="xl" textAlign="center">
                Invite Folks to your Chama
              </Text>
              <Text fontSize="md" textAlign="center" color="teal.500">
                Search and add people in your chama. You can skip this and add
                members later.
              </Text>
              <FormControl>
                <TagPhoneInputGroup
                  phoneTags={invites}
                  setPhoneTags={setInvites}
                />
              </FormControl>
            </>
          );
      }
    };

    return (
      <Flex flexDirection="column" gap="5">
        <CreateChamaStepper
          steps={chamaSteps}
          active={modalView}
          setModalView={setModalView}
        />
        {modalBody()}
      </Flex>
    );
  }, [
    name,
    description,
    invites,
    modalView,
    setName,
    setDescription,
    setInvites,
    setModalView,
  ]);

  const getModalActions = useCallback(() => {
    const cancel = (
      <Button
        size="lg"
        onClick={() => closeModal()}
        variant="ghost"
        colorScheme="red"
      >
        Cancel
      </Button>
    );

    switch (modalView) {
      case CreateChamaModalView.NameChama:
        return (
          <>
            <Button
              size="lg"
              colorScheme="teal"
              onClick={() => setModalView(CreateChamaModalView.DescribeChama)}
            >
              Continue
            </Button>
            {cancel}
          </>
        );
      case CreateChamaModalView.DescribeChama:
        return (
          <>
            <Button
              size="lg"
              colorScheme="teal"
              onClick={() => setModalView(CreateChamaModalView.ConfigureChama)}
            >
              Continue
            </Button>
            {cancel}
          </>
        );
      case CreateChamaModalView.ConfigureChama:
        return (
          <>
            <Button
              size="lg"
              colorScheme="teal"
              onClick={() => setModalView(CreateChamaModalView.InviteFolks)}
            >
              Continue
            </Button>
            {cancel}
          </>
        );
      case CreateChamaModalView.InviteFolks:
        return (
          <>
            <Button onClick={createChama} variant="solid" colorScheme="teal">
              Create Chama
            </Button>
            {cancel}
          </>
        );
    }
  }, [modalView, createChama, closeModal]);

  const getModalFooter = useCallback(() => {
    return (
      <ButtonGroup
        w="100%"
        display="flex"
        flexDirection="row"
        flexWrap={{ base: "wrap", lg: "nowrap" }}
        justifyContent="space-between"
        spacing={{ base: "0", lg: "4" }}
        gap={"2"}
      >
        {getModalActions()}
      </ButtonGroup>
    );
  }, [getModalActions]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={() => closeModal()}
      header={<Text>{chama ? `Edit ${chama.name}` : "Create New Chama"}</Text>}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
});

interface CreateChamaStepperProps {
  active: number;
  steps: CreateChamaModalView[];
  setModalView: (view: CreateChamaModalView) => void;
}

const CreateChamaStepper = React.memo(function CreateChamaStepper({
  steps,
  active,
  setModalView,
}: CreateChamaStepperProps) {
  const { activeStep, setActiveStep } = useSteps({
    index: active,
    count: steps.length,
  });

  useEffect(() => {
    setActiveStep(active);
  }, [active, setActiveStep]);

  return (
    <Stepper size="lg" colorScheme="teal" index={activeStep}>
      {steps.map((step, index) => (
        <Step key={index} onClick={() => setModalView(step)}>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>
          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
});
