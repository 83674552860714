import React from "react";
import { Center, Flex, Heading, Text } from "@chakra-ui/react";

interface SectionTemplateProps {
  heading: string;
  subheading: string;
  children: React.ReactNode;
}

export const SectionTemplate = React.memo(function SectionTemplate({
  heading,
  subheading,
  children,
}: SectionTemplateProps) {
  return (
    <Flex
      minH={"80vh"}
      flexDir={"column"}
      gap={{ base: "8", md: "16" }}
      width={"100%"}
      height={"100%"}
      pb={"100px"}
    >
      <Heading
        fontSize={{ base: "30px", md: "48px" }}
        fontFamily={"body"}
        textAlign={"center"}
      >
        {heading}
      </Heading>
      <Center>
        <Text
          textAlign={"center"}
          maxW={"720px"}
          mt={12}
          fontFamily={"body"}
          fontSize={{ base: "18px", lg: "24px" }}
        >
          {subheading}
        </Text>
      </Center>
      {children}
    </Flex>
  );
});
