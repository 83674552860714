import React, { useCallback, useEffect, useState } from "react";
import { useJwt } from "react-jwt";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import {
  Chama,
  ChamaInvite,
  ChamaMemberRole,
  UpdateChama,
} from "@bitsacco/types";
import { AuthExperience, RouterLink, useApi, useAuth } from "../components";
import { ControlledAuth } from "./Auth";
import { TOAST_TIMEOUT_MS } from "../configs";

export const Join = React.memo(function Join(): JSX.Element {
  const { bitsacco } = useApi();
  const { nextRedirect, user } = useAuth();

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { decodedToken } = useJwt<ChamaInvite>(searchParams.get("t") || "");

  const [phone, setPhone] = useState("");
  const [showAuth, setShowAuth] = useState(false);
  const [authExperience, setAuthExperience] = useState<AuthExperience>(
    AuthExperience.Signup,
  );
  const [joinError, setJoinError] = useState<string>("");

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && showAuth) {
      setShowAuth(false);
    }
  }, [user, showAuth]);

  useEffect(() => {
    if (decodedToken) {
      setPhone(decodedToken.user);
      nextRedirect(`${location.pathname}${location.search}`);
    }
  }, [decodedToken, location, nextRedirect]);

  const joinChama = useCallback(() => {
    if (!decodedToken || !user) {
      return;
    }

    (async () => {
      try {
        const { id } = decodedToken.chama;
        const updated = await bitsacco.request<Chama, UpdateChama>(
          "PATCH",
          "/chama/update",
          // TODO: check if this can overwrite an existing member!
          {
            id,
            updates: {
              members: {
                [user.id]: {
                  id: user.id,
                  role: ChamaMemberRole.Member,
                  contributions: 0,
                  withdrawals: 0,
                  penalties: 0,
                },
              },
            },
          },
        );

        if (updated) {
          setJoinError("");
          return navigate("/chama");
        }

        throw "failed to update account info";
      } catch (e) {
        setJoinError(`${e}`);
        toast({
          title: "Warning",
          description: "Failed to join chama",
          status: "warning",
          duration: TOAST_TIMEOUT_MS,
          isClosable: true,
        });
      }
    })();
  }, [bitsacco, user, decodedToken, toast, navigate, setJoinError]);

  return showAuth ? (
    <ControlledAuth
      {...{ phone, setPhone, authExperience, setAuthExperience }}
    />
  ) : (
    <Flex direction="column" flexGrow={1} basis="100%" gap="5" p="5">
      <Center pt="10">
        <VStack>
          <Card align="center" w={"400px"} px={"5"}>
            <CardHeader>
              <Text fontSize="xl" fontWeight="semibold">
                Join {decodedToken?.chama.name}
              </Text>
            </CardHeader>
            <CardBody>
              <Flex align="center" flexDir={"column"} gap={4}>
                <Text textAlign="center">
                  this is a{" "}
                  <span
                    style={{
                      textTransform: "uppercase",
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "teal",
                    }}
                  >
                    BITSACCO
                  </span>{" "}
                  chama with the description
                </Text>
                <Text textAlign="center">
                  <em>{decodedToken?.chama.description}</em>
                </Text>
                {joinError && (
                  <Text color="red.500" textAlign="center">
                    {joinError}
                  </Text>
                )}
                {user ? (
                  <Button
                    variant="solid"
                    colorScheme="teal"
                    w="100%"
                    onClick={joinChama}
                  >
                    Join Chama
                  </Button>
                ) : (
                  <Button
                    variant="solid"
                    colorScheme="teal"
                    w="100%"
                    onClick={() => setShowAuth(true)}
                  >
                    Signup / Login
                  </Button>
                )}
              </Flex>
            </CardBody>
          </Card>
          <RouterLink to="/">
            <Button variant="link">no thanks</Button>
          </RouterLink>
        </VStack>
      </Center>
    </Flex>
  );
});
