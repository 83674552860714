"use strict";
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.7
//   protoc               v3.21.12
// source: swap.proto
Object.defineProperty(exports, "__esModule", { value: true });
exports.Currency = void 0;
/** Currency: Enum representing supported currencies. */
var Currency;
(function (Currency) {
    Currency[Currency["BTC"] = 0] = "BTC";
    Currency[Currency["KES"] = 1] = "KES";
    Currency[Currency["UNRECOGNIZED"] = -1] = "UNRECOGNIZED";
})(Currency || (exports.Currency = Currency = {}));
