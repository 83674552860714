import { SESSION_COOKIE_KEY, SESSION_HEADER_KEY } from "@bitsacco/types";
import {
  getSessionValue,
  setSessionValue,
  SESSION_USER_KEY,
  removeSessionValue,
} from "./services/storage";
import { BS_API_URL } from "./configs";

const _auth = () => console.log("unauthorized");

export class BitsaccoApi {
  constructor(private auth: () => void = _auth) {}

  request = async <T, B>(
    method: string,
    api: string,
    body?: B,
  ): Promise<T | undefined> => {
    return request(this.auth, BS_API_URL, api, method, body);
  };
}

const request = async <T, B>(
  auth: () => void,
  url: string,
  path: string,
  method: string,
  body?: B,
): Promise<T | undefined> => {
  try {
    const options: RequestInit = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getSessionValue(SESSION_COOKIE_KEY)}`,
        Origin: `https://0.0.0.0`,
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const res = await fetch(`${url}${path}`, options);

    // HACK: is there a better way of recording new sessions?
    const SessionCreatePaths = [
      "/user/login",
      "/user/register",
      "/user/recover",
    ];

    if (SessionCreatePaths.includes(path)) {
      const sessionId = res.headers.get(SESSION_HEADER_KEY);
      sessionId && setSessionValue(SESSION_COOKIE_KEY, sessionId);
    }

    if (res.ok) {
      const data = await res.json();
      return data as unknown as T;
    }

    if (res.status === 401) {
      removeSessionValue(SESSION_COOKIE_KEY);
      removeSessionValue(SESSION_USER_KEY);

      auth();
      return;
    }

    const text = await res.text();
    console.log(text);
  } catch (e) {
    console.error(e);
    return;
  }
};
