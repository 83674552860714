import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Text,
} from "@chakra-ui/react";
import { SectionTemplate } from "./SectionTemplate";

interface FAQItem {
  question: string;
  answer: string;
}

export const FAQ = React.memo(function FAQ() {
  const items: FAQItem[] = [
    {
      question: "What is a SACCO?",
      answer:
        "A SACCO is a Savings and Credit Cooperative Organization. It is a financial institution that is owned by its members who share a common bond.",
    },
    {
      question: "What is a Chama?",
      answer:
        "A Chama is a group of people who come together to pool their resources and make investments together. It is a form of a savings group.",
    },
    {
      question: "Which Chama should I join?",
      answer:
        "We recommend you only join a well-established chama in which you know atleast three other members personally",
    },
    {
      question: "Can I use Bitsacco without joining a Chama?",
      answer:
        "Yes, you can use Bitsacco to save and invest in Bitcoin without joining a Chama. You can also create your own Chama and invite your friends to join.",
    },
    {
      question: "What is Bitcoin?",
      answer:
        "Bitcoin is a virtual currency that can be used by anyone to make transactions. It is the worlds first neutral currency that is not controlled by any entity government or financial institution.",
    },
    {
      question: "Why should I save in Bitcoin?",
      answer:
        "Saving in Bitcoin is a great way to protect your wealth from inflation and currency devaluation. Bitcoin is a deflationary currency that has a limited supply, which means it cannot be devalued by printing more money.",
    },
    {
      question: "How volatile is Bitcoin?",
      answer:
        "The price of Bitcoin can go up and down. However, over the long term, the price of Bitcoin has been increasing steadily. It is important to have a long term investment horizon when investing in Bitcoin.",
    },
    {
      question: "How do I get my first Bitcoin?",
      answer:
        "Using Bitsacco, you can buy your first Bitcoin by depositing Kenya shillings into your personal savings or Chama. We automatically convert shillings into Bitcoin and help you store it securely.",
    },
    {
      question: "How do I get Kenya shillings from my savings in Bitsacco?",
      answer:
        "When you withdraw your savings from Bitsacco, we convert your Bitcoin into Kenya shillings and instantly send the money into your M-Pesa account.",
    },
    {
      question: "Will Bitsacco offer loans backed by Bitcoin?",
      answer:
        "We will be offering loans to our members in the future. We are currently working on building the infrastructure to offer loans to our members. Stay tuned for updates on this.",
    },
    {
      question: "Is Bitsacco licensed and registered?",
      answer:
        "Yes, Bitsacco is registered and regulated by SASRA - the SACCO Societies Regulatory Authority. We operate in Kenya and are compliant with all the laws and regulations governing SACCOs in Kenya.",
    },
  ];

  return (
    <SectionTemplate
      heading="LEARN MORE ABOUT BITSACCO"
      subheading="These are the most frequently asked questions about Bitsacco and related topics. Have a query not listed here, please contact us"
    >
      <Flex justifyContent="center" alignItems={"center"}>
        <Box p={4} w={{ base: "100%", md: "80%" }} height={"100%"}>
          <Accordion allowToggle>
            {items.map((item, index) => (
              <AccordionItem key={index}>
                <h2>
                  <AccordionButton
                    _expanded={{ bg: "teal", color: "white" }}
                    gap={4}
                  >
                    <AccordionIcon />
                    <Box flex="1" textAlign="left">
                      <Text
                        fontFamily={"body"}
                        fontSize={{ base: "18px", lg: "24px" }}
                      >
                        {item.question}
                      </Text>
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text
                    fontFamily={"body"}
                    fontSize={{ base: "16px", lg: "18px" }}
                    py={2}
                  >
                    {item.answer}
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      </Flex>
    </SectionTemplate>
  );
});
