import React from "react";
import {
  Button,
  Flex,
  Text,
  Heading,
  useDisclosure,
  Center,
  Spinner,
  Alert,
  AlertIcon,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { Chama } from "@bitsacco/types";
import {
  useAuth,
  ChamaCard,
  useAppState,
  FeatureTease,
  CreateChamaModal,
  ChamaSavingsInfoModal,
} from "../components";
import { digitizePhone, fetcher } from "../utils";
import { useQuery } from "@tanstack/react-query";
import { BS_API_URL } from "../configs";
import { QueryKeys } from "../enums/QueryKeys";
import { FaInfoCircle, FaPlus } from "react-icons/fa";
import { ReactComponent as CommunityIcon } from "../assets/svgs/community.svg";

export const Chamas = React.memo(function Chamas(): JSX.Element {
  const { user } = useAuth();
  const { memberStatus } = useAppState();

  const {
    isOpen: showCreateChamaModal,
    onOpen: onOpenCreateChamaModal,
    onClose: onCloseCreateChamaModal,
  } = useDisclosure();

  const {
    isOpen: showChamaInfoModal,
    onOpen: onOpenChamaInfoModal,
    onClose: onCloseChamaInfoModal,
  } = useDisclosure();

  const {
    data: chamas = [],
    isLoading: fetchLoading,
    error: fetchError,
  } = useQuery<Chama[], Error>({
    queryKey: [QueryKeys.CHAMAS],
    queryFn: async () => {
      const fetchedChamas = await fetcher<Chama[], Error>(
        `${BS_API_URL}/chama/all`,
      );
      if (user?.id) {
        return fetchedChamas.filter((chama) =>
          Object.keys(chama.members).includes(digitizePhone(user.id)),
        );
      }
      return [];
    },
    enabled: !!user?.id,
  });

  if (fetchLoading)
    return (
      <Center w="100%" pt="5rem">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    );
  if (fetchError)
    return (
      <Alert status="error" mt="5rem">
        <AlertIcon />
        There was an error processing your request
      </Alert>
    );

  return (
    <>
      <Flex direction="column" flexGrow={1} basis="100%" gap="5" p="5" pb="20">
        <Flex
          p="5"
          gap="4"
          direction="row"
          flexWrap={{ base: "wrap", lg: "nowrap" }}
          align="center"
          justifyItems="center"
          justify="space-between"
        >
          <Flex
            direction="row"
            flexWrap={{ base: "wrap", lg: "nowrap" }}
            justify={{ base: "start", lg: "center" }}
            align={{ base: "start", lg: "center" }}
            gap={2}
          >
            <Heading size="md">CHAMA SAVINGS</Heading>
            <Text>Manage funds together with friends and family</Text>
          </Flex>
          <Flex
            direction="row"
            flexWrap={{ base: "wrap", lg: "nowrap" }}
            justify={{ base: "start", lg: "center" }}
            align={{ base: "start", lg: "center" }}
            gap={2}
          >
            <Button
              variant="outline"
              colorScheme="green"
              height="35px"
              onClick={onOpenCreateChamaModal}
              isDisabled={!memberStatus.hasShares}
            >
              <Icon as={FaPlus} mr="2" />
              create chama
            </Button>
            <IconButton
              variant="outline"
              colorScheme="green"
              aria-label="show chama chat"
              onClick={onOpenChamaInfoModal}
              icon={<Icon as={FaInfoCircle} />}
            />
          </Flex>
        </Flex>
        {memberStatus.hasShares ? (
          <Flex direction="column" gap="10">
            {user &&
              chamas.map((chama, idx) => (
                <ChamaCard key={idx} user={user} chama={chama} />
              ))}
          </Flex>
        ) : (
          <FeatureTease
            img={CommunityIcon}
            title="Welcome to Bitsacco Chama Savings!"
          />
        )}
      </Flex>

      {user && (
        <CreateChamaModal
          user={user}
          chama={undefined}
          isOpen={showCreateChamaModal}
          onClose={() => onCloseCreateChamaModal()}
        />
      )}

      <ChamaSavingsInfoModal
        isOpen={showChamaInfoModal}
        onClose={() => onCloseChamaInfoModal()}
      />
    </>
  );
});
