import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate,
  useLocation,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import {
  Auth,
  Home,
  Hero,
  Savings,
  Chamas,
  ErrorBoundary,
  ErrorPage,
  Account,
  Join,
  Transaction,
  MemberAdmin,
} from "./pages";
import { useAuth } from "./components";
import { SaccoMembership } from "./pages/Membership";
import { Routes } from "./routes";

const theme = extendTheme({
  fonts: {
    heading: "Satoshi, sans-serif",
    body: "Nunito, sans-serif",
    title: "Poppins, sans-serif",
  },
});

export const ProtectedRoute = React.memo(function ProtectedRoute({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to={Routes.Auth} state={{ from: location.pathname }} />;
  }

  return <>{children}</>;
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={Routes.Root} element={<Home />} errorElement={<ErrorPage />}>
      <Route index element={<Hero />} />
      <Route path={Routes.Auth} element={<Auth />} />
      <Route
        path={Routes.Personal}
        element={
          <ProtectedRoute>
            <Savings />
          </ProtectedRoute>
        }
      />
      <Route
        path={Routes.Account}
        element={
          <ProtectedRoute>
            <Account />
          </ProtectedRoute>
        }
      />
      <Route path={Routes.Chama}>
        <Route
          index
          element={
            <ProtectedRoute>
              <Chamas />
            </ProtectedRoute>
          }
        />
        <Route path={Routes.Join} element={<Join />} />
        <Route path={Routes.Transaction} element={<Transaction />} />
      </Route>
      <Route
        path={Routes.Membership}
        element={
          <ProtectedRoute>
            <>
              <SaccoMembership />
            </>
          </ProtectedRoute>
        }
      />
      <Route path={Routes.Admin}>
        <Route
          path={Routes.MemberAdmin}
          element={
            <ProtectedRoute>
              <MemberAdmin />
            </ProtectedRoute>
          }
        />
        {/* <Route path={Routes.ChamaAdmin} element={<ProtectedRoute><MemberAdmin /></ProtectedRoute>} /> */}
      </Route>
    </Route>,
  ),
);
const queryClient = new QueryClient();

const app = (
  <ErrorBoundary fallback={<ErrorPage />}>
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <ChakraProvider theme={theme}>
          <RouterProvider router={router} />
        </ChakraProvider>
      </React.StrictMode>
    </QueryClientProvider>
  </ErrorBoundary>
);

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("Failed to find the root element");
}

const existingRoot = (rootElement as unknown as any)._reactRootContainer;
if (existingRoot) {
  existingRoot.render(app);
} else {
  const root = ReactDOM.createRoot(rootElement);
  root.render(app);
}
