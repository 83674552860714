import React, { useCallback, useState } from "react";
import { Button, ButtonGroup, Flex, FormControl, Text } from "@chakra-ui/react";
import { User } from "@bitsacco/types";

import { TemplateModal } from "./TemplateModal";
import { ShareInputGroup } from "../InputGroups";
import { getProfileLabel } from "../../utils";

interface AssignShareModalProps {
  user: User;
  isOpen: boolean;
  onClose: () => void;
  assignShare: (shares: number) => void;
}

export const AssignShareModal = React.memo(function AssignShareModal({
  user,
  isOpen,
  onClose,
  assignShare,
}: AssignShareModalProps): JSX.Element {
  const [shareOrder, setShareOrder] = useState<number>(0);

  const closeModal = useCallback(() => {
    setShareOrder(0);
    onClose();
  }, [onClose, setShareOrder]);

  const getModalBody = useCallback(() => {
    return (
      <Flex flexDirection="column" gap="5">
        <Text fontSize="md" color="teal.500">
          Assign shares to {getProfileLabel(user)}. Each share is worth 1000 KES
        </Text>
        <FormControl>
          <ShareInputGroup
            shares={shareOrder}
            setShare={setShareOrder}
            getFormHelperText={() => <></>}
          />
        </FormControl>
      </Flex>
    );
  }, [shareOrder, setShareOrder]);

  const getModalActions = useCallback(() => {
    return (
      <>
        <Button onClick={() => assignShare(shareOrder)}>Assign Shares</Button>
        <Button
          size="lg"
          onClick={closeModal}
          variant="ghost"
          colorScheme="red"
        >
          Cancel
        </Button>
      </>
    );
  }, [shareOrder, assignShare, closeModal]);

  const getModalFooter = useCallback(() => {
    return (
      <ButtonGroup
        w="100%"
        display="flex"
        flexDirection="row"
        flexWrap={{ base: "wrap", lg: "nowrap" }}
        justifyContent="space-between"
        spacing={{ base: "0", lg: "4" }}
        gap={"2"}
      >
        {getModalActions()}
      </ButtonGroup>
    );
  }, [getModalActions]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={closeModal}
      header={<Text>{`Assign Shares to ${getProfileLabel(user)}`}</Text>}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
});
