import React, { useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { TemplateModal } from "./TemplateModal";

interface ChamaSavingsInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ChamaSavingsInfoModal = React.memo(function ChamaSavingsInfoModal({
  isOpen,
  onClose,
}: ChamaSavingsInfoModalProps): JSX.Element {
  const getModalBody = useCallback(() => {
    return (
      <Flex flexDirection="column" gap="5">
        <Text>
          Bitsacco supports group savings, in the model of Chamas. A fully
          registered Bitsacco member can:
        </Text>
        <UnorderedList pl={4} spacing={3}>
          <ListItem>Create one or more Bitsacco Chamas</ListItem>
          <ListItem>Invite other members to be part of the Chama</ListItem>
          <ListItem>
            Manage and co-administer the Chama Savings Bitcoin account
          </ListItem>
        </UnorderedList>
        <Text>
          We will share updates on the following critical membership features
        </Text>
        <UnorderedList pl={4} spacing={3}>
          <ListItem>Transferring shares among members</ListItem>
          <ListItem>
            Listing beneficiary / next of kin for subscribed shares
          </ListItem>
        </UnorderedList>
      </Flex>
    );
  }, []);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={onClose}
      header={<Text>About Bitsacco Chama Savings</Text>}
      body={getModalBody()}
      footer={
        <Button onClick={onClose} variant="outline" colorScheme="teal">
          I acknowledge and understand
        </Button>
      }
    />
  );
});
