import React, { useCallback, useEffect, useState } from "react";
import {
  IconButton,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tag,
  TagLabel,
  Text,
  useTheme,
  Flex,
} from "@chakra-ui/react";
import { ChamaMember, ChamaMemberRole, ChamaRule, User } from "@bitsacco/types";
import { FetchHeadshot, Headshot } from "../Headshot";
import { FaEllipsisVertical } from "react-icons/fa6";
import { ComingSoon } from "../ComingSoon";
import { fetcher, getProfileLabel } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "../../enums/QueryKeys";
import { BS_API_URL } from "../../configs";

interface MemberInfoProps {
  member: ChamaMember;
  user: User;
  showMenu: boolean;
  adminCount: number;
  configureAdmin: (member: ChamaMember) => void;
  removeMember: () => void;
}

export const MemberInfo = React.memo(function MemberInfo({
  member,
  user,
  showMenu,
  adminCount,
  configureAdmin,
  removeMember,
}: MemberInfoProps) {
  const [isMe] = useState(user.id === member.id);
  const [isAdmin] = useState(member.role === ChamaMemberRole.Admin);
  const theme = useTheme();

  const { data: usr } = useQuery<User, Error>({
    queryKey: [QueryKeys.FIND_USER, member.id],
    queryFn: async () => {
      if (isMe) {
        return user;
      }

      return await fetcher<User, Error>(
        `${BS_API_URL}/user/find/id/${member.id}`,
      );
    },
    enabled: !!member.id,
  });

  const getLabel = useCallback(() => {
    if (isMe && isAdmin) {
      return "You ( admin )";
    }

    if (isMe) {
      return "You";
    }

    if (usr) {
      const label = getProfileLabel(usr);

      if (isAdmin) {
        return `${label} ( admin )`;
      }

      return label;
    }

    if (isAdmin) {
      return "admin";
    }

    return "member";
  }, [usr, isMe, isAdmin]);

  return (
    <Tag
      borderRadius={20}
      borderColor={theme.colors.gray[300]}
      borderWidth={2}
      minW="fit-content"
      px={3}
      py={2}
    >
      {isMe ? (
        <Headshot user={user} size="sm" ml={-1} mr={2} />
      ) : (
        <FetchHeadshot id={member.id} size="sm" ml={-1} mr={2} />
      )}

      <TagLabel fontSize={"bold"}>{getLabel()}</TagLabel>
      {showMenu && (
        <MemberMenu
          isMe={isMe}
          isAdmin={isAdmin}
          assignAdmin={() =>
            configureAdmin({ ...member, role: ChamaMemberRole.Admin })
          }
          dismissAdmin={
            adminCount > 1
              ? () => {
                  configureAdmin({ ...member, role: ChamaMemberRole.Member });
                }
              : undefined
          }
          removeMember={removeMember}
        />
      )}
    </Tag>
  );
});

interface MemberMenuProps {
  isMe: boolean;
  isAdmin: boolean;
  assignAdmin: () => void;
  dismissAdmin?: () => void;
  removeMember: () => void;
}

const MemberMenu = React.memo(function MemberMenu({
  isMe,
  isAdmin,
  assignAdmin,
  dismissAdmin,
}: MemberMenuProps) {
  return (
    <Menu isLazy>
      <MenuButton
        variant="ghost"
        as={IconButton}
        aria-label="Options"
        icon={<FaEllipsisVertical />}
        size="sm"
        ml={2}
      />
      <MenuList>
        {isAdmin && dismissAdmin && (
          <MenuItem onClick={dismissAdmin}>
            {isMe ? "Resign" : "Dismiss"} as admin
          </MenuItem>
        )}
        {!isAdmin && (
          <MenuItem onClick={assignAdmin}>Make chama admin</MenuItem>
        )}
        <MenuItem>
          <Flex align="center" justify="space-between" gap={2}>
            {isMe ? "Exit this" : "Remove from"} chama
            <ComingSoon />
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  );
});

export const RuleInfo = React.memo(function RuleInfo({
  rule,
  description,
}: ChamaRule) {
  return (
    <ListItem>
      <Text fontSize="md">{rule}</Text>
      {description && <Text fontSize="sm">{description}</Text>}
    </ListItem>
  );
});
