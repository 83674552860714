import React, { useCallback, useState } from "react";
import {
  Button,
  ButtonGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
  Flex,
  Box,
  Text,
  Divider,
  AbsoluteCenter,
  useColorModeValue,
} from "@chakra-ui/react";
import { TemplateModal } from "../modal/TemplateModal";
import { useApi } from "../Providers";
import { TransactionState } from "../TransactionState";
import { Chama, ChamaTx } from "@bitsacco/types";
import { ComingSoon } from "../ComingSoon";

interface ChamaWithdrawModalProps {
  tx: ChamaTx;
  chama: Chama;
  isOpen: boolean;
  onClose: () => void;
}

export const ChamaWithdrawModal = function ChamaWithdrawModal({
  chama,
  tx,
  isOpen,
  onClose,
}: ChamaWithdrawModalProps): JSX.Element {
  const { webln } = useApi();

  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const bg = useColorModeValue(theme.colors.gray[50], theme.colors.gray[700]);

  const getExtraWithdrawActions = useCallback(() => {
    return (
      <Button onClick={onClose} variant="outline" colorScheme="red">
        Cancel
      </Button>
    );
  }, [tx, onClose]);

  const getModalHeader = useCallback(() => {
    return <Text>Withdraw funds from {chama.name}</Text>;
  }, [chama]);

  const getModalBody = useCallback(() => {
    return (
      <Tabs
        isFitted
        colorScheme={theme.colors.teal[50]}
        onChange={(index) => setTabIndex(index)}
        defaultIndex={tabIndex}
      >
        <TabList mb="1em">
          <Tab>Use Mpesa</Tab>
          <Tab>Use Lightning</Tab>
        </TabList>
        <TabPanels minH="16em">
          <TabPanel>
            <Flex
              flexDirection="column"
              gap="5"
              h="100%"
              justify="center"
              align="center"
            >
              <ComingSoon />
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex
              flexDirection="column"
              gap="5"
              h="100%"
              justify="center"
              align="center"
            >
              <ComingSoon />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }, [chama, tx, webln, theme, tabIndex, setTabIndex, bg]);

  const getModalFooter = useCallback(() => {
    return <ButtonGroup spacing="2">{getExtraWithdrawActions()}</ButtonGroup>;
  }, [getExtraWithdrawActions]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={onClose}
      header={getModalHeader()}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
};
