"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TxPath = exports.ChamaTxPath = exports.ChamaTxState = exports.ChamaTxType = exports.ChamaTxReview = void 0;
var ChamaTxReview;
(function (ChamaTxReview) {
    ChamaTxReview[ChamaTxReview["Approve"] = 0] = "Approve";
    ChamaTxReview[ChamaTxReview["Reject"] = 1] = "Reject";
})(ChamaTxReview || (exports.ChamaTxReview = ChamaTxReview = {}));
var ChamaTxType;
(function (ChamaTxType) {
    ChamaTxType["Deposit"] = "DEPOSIT";
    ChamaTxType["Withdrawal"] = "WITHDRAWAL";
})(ChamaTxType || (exports.ChamaTxType = ChamaTxType = {}));
var ChamaTxState;
(function (ChamaTxState) {
    ChamaTxState["Pending"] = "PENDING";
    ChamaTxState["Approved"] = "APPROVED";
    ChamaTxState["Rejected"] = "REJECTED";
    ChamaTxState["Complete"] = "COMPLETE";
    ChamaTxState["Failed"] = "FAILED";
})(ChamaTxState || (exports.ChamaTxState = ChamaTxState = {}));
var ChamaTxPath;
(function (ChamaTxPath) {
    ChamaTxPath["Lightning"] = "LIGHTNING";
    ChamaTxPath["Mpesa"] = "MPESA";
})(ChamaTxPath || (exports.ChamaTxPath = ChamaTxPath = {}));
// generic tx path to replace ChamaTxPath
var TxPath;
(function (TxPath) {
    TxPath["Lightning"] = "LIGHTNING";
    TxPath["Mpesa"] = "MPESA";
})(TxPath || (exports.TxPath = TxPath = {}));
