"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LightningFromMpesaState = exports.MPESA_TRANSACTION_UPDATE_CHALLENGE = exports.MpesaTransactionState = exports.MpesaTransactionType = void 0;
var MpesaTransactionType;
(function (MpesaTransactionType) {
    MpesaTransactionType["Deposit"] = "DEPOSIT";
    MpesaTransactionType["Withdrawal"] = "WITHDRAWAL";
})(MpesaTransactionType || (exports.MpesaTransactionType = MpesaTransactionType = {}));
var MpesaTransactionState;
(function (MpesaTransactionState) {
    MpesaTransactionState["Pending"] = "PENDING";
    MpesaTransactionState["Processing"] = "PROCESSING";
    MpesaTransactionState["Retry"] = "RETRY";
    MpesaTransactionState["Failed"] = "FAILED";
    MpesaTransactionState["Complete"] = "COMPLETE";
})(MpesaTransactionState || (exports.MpesaTransactionState = MpesaTransactionState = {}));
exports.MPESA_TRANSACTION_UPDATE_CHALLENGE = "BITSACCO";
var LightningFromMpesaState;
(function (LightningFromMpesaState) {
    LightningFromMpesaState["Pending"] = "PENDING";
    LightningFromMpesaState["Processing"] = "PROCESSING";
    LightningFromMpesaState["Failed"] = "FAILED";
    LightningFromMpesaState["Complete"] = "COMPLETE";
})(LightningFromMpesaState || (exports.LightningFromMpesaState = LightningFromMpesaState = {}));
