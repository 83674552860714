import React, { useCallback, useEffect } from "react";
import { NostrId, NostrIdUnsafe } from "@bitsacco/types";
import { CopyIcon, SunIcon } from "@chakra-ui/icons";
import { useToast, Heading, Flex, IconButton, Text } from "@chakra-ui/react";
import { TOAST_TIMEOUT_MS } from "../../configs";
import { getNostrIdFromLocal, createNewNostrId } from "../../services";
import { copyToClipboard, truncateText } from "../../utils";
import { SectionProps } from "./types";
import { ComingSoon } from "../ComingSoon";

interface AccountNostrProps extends SectionProps, NostrId {}

export const AccountNostr = React.memo(function AccountNostr({
  hpub,
  npub,
  address,
  bgBox,
  bdBox,
}: AccountNostrProps) {
  const [id, setId] = React.useState<NostrIdUnsafe>({ hpub, npub, address });
  const toast = useToast();

  useEffect(() => {
    (async () => {
      const local = await getNostrIdFromLocal();

      if (!local.hsec) {
        if (!hpub && !npub && !address) {
          const nid = await createNewNostrId();
          return setId(nid);
        }
      }

      if (!npub || !hpub) {
        return setId(local);
      }

      setId({
        hpub,
        npub,
        address,
        hsec: local.hsec,
      });
    })();
  }, [hpub, npub, address]);

  const copyNpub = useCallback(() => {
    if (!id.npub) {
      return;
    }

    (async () => {
      try {
        await copyToClipboard(id.npub || "");
        toast({
          title: "Success",
          description: `Copied public key to clipboard.`,
          status: "success",
          duration: TOAST_TIMEOUT_MS,
          isClosable: true,
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [id.npub, toast]);

  const copyHpub = useCallback(() => {
    if (!id.hpub) {
      return;
    }

    (async () => {
      try {
        await copyToClipboard(id.hpub || "");
        toast({
          title: "Success",
          description: `Copied public key to clipboard.`,
          status: "success",
          duration: TOAST_TIMEOUT_MS,
          isClosable: true,
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [id.hpub, toast]);

  return (
    <>
      <Heading as="h2" size="md">
        <Flex align="center" justify="space-between" gap={2}>
          Universal Identity (nostr)
          <ComingSoon />
        </Flex>
      </Heading>

      <Text>
        We use the following universal identity in the chama chats, and other
        features that cant use phone numbers
      </Text>

      <Flex
        align="center"
        justify="space-between"
        p={4}
        bg={bgBox}
        border={`2px solid ${bdBox}`}
        borderRadius="md"
      >
        <Text>public key</Text>
        {id.npub && (
          <>
            <Text>{truncateText(id.npub, 10, 10)}</Text>
            <IconButton
              aria-label="Copy Nostr Address"
              icon={<CopyIcon />}
              onClick={copyNpub}
            />
          </>
        )}
        {id.hpub && !id.npub && (
          <>
            <Text>{truncateText(id.hpub, 10, 10)}</Text>
            <IconButton
              aria-label="Copy Nostr Address"
              icon={<CopyIcon />}
              onClick={copyHpub}
            />
          </>
        )}
      </Flex>

      {id.address && (
        <Flex
          align="center"
          justify="space-between"
          p={4}
          bg={bgBox}
          border={`2px solid ${bdBox}`}
          borderRadius="md"
        >
          <Text>address</Text>
          <Text>{id.address}</Text>
          <IconButton aria-label="Copy Nostr Address" icon={<SunIcon />} />
        </Flex>
      )}
    </>
  );
});
