import React from "react";
import { Tr, Td, Button } from "@chakra-ui/react";

interface TableProps {
  action?: string;
  shares?: number;
  dateHappened?: string;
  createdAt?: string;
  amountMsats?: number;
  amountFiat?: number;
  status?: React.ReactNode;
  moreActions: React.ReactNode;
}

export const TableDisplay = React.memo(function TableDisplay({
  action,
  shares,
  dateHappened,
  moreActions,
}: TableProps): JSX.Element {
  return (
    <Tr>
      <Td>{action}</Td>
      <Td>{shares}</Td>
      <Td>{dateHappened}</Td>
      <Td>
        <Button size="sm" variant="ghost" _hover={{ background: "none" }}>
          {moreActions}
        </Button>
      </Td>
    </Tr>
  );
});
