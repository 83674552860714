import React, { useCallback, useEffect, useState } from "react";
import {
  Text,
  Flex,
  Heading,
  Card,
  Button,
  CardBody,
  CardFooter,
  ButtonGroup,
  useDisclosure,
  Icon,
  Tbody,
  Thead,
  Tr,
  Th,
  Table,
  useToast,
  Td,
} from "@chakra-ui/react";
import { FaInfoCircle } from "react-icons/fa";
import {
  btcToFiat,
  WalletMeta,
  UserTxsResponse,
  UserTxsRequest,
  PaginatedSolowalletTxsResponse,
  User,
} from "@bitsacco/types";
import {
  useAuth,
  useQuote,
  useAppState,
  FeatureTease,
  PersonalSavingsInfoModal,
  PersonalSavingsDepositModal,
  PersonalSavingsWithdrawModal,
  getTxStatusBadge,
  getTxTypeBadge,
  TransactionTarget,
  TableMenu,
} from "../components";
import { fetcher, formatNumber } from "../utils";
import { ReactComponent as SavingsIcon } from "../assets/svgs/savings.svg";
import { BS_API_GATEWAY_URL } from "../configs";

export const Savings = React.memo(function AccessSavings(): JSX.Element {
  const { user } = useAuth();
  const { memberStatus } = useAppState();
  const [userTransactions, setUserTransactions] = useState<
    | {
        ledger?: PaginatedSolowalletTxsResponse;
        meta?: WalletMeta;
      }
    | undefined
  >();

  const toast = useToast();

  const {
    isOpen: showPersonalSavingsInfoModal,
    onOpen: onOpenPersonalSavingsInfoModal,
    onClose: onClosePersonalSavingsInfoModal,
  } = useDisclosure();

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const handleOptionSelect = async (option: string) => {
    if (option === "Copy ID") {
      const userId = user?.id || "";
      await navigator.clipboard.writeText(userId);
      toast({
        title: "Copied successfully!",
        description: `User ID ${userId} copied to clipboard.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const paginatedTransactions = userTransactions?.ledger?.transactions?.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage,
  );

  const totalPages = Math.ceil(
    (userTransactions?.ledger?.transactions?.length || 0) / itemsPerPage,
  );

  useEffect(() => {
    if (!user) return;

    (async () => {
      try {
        const data = await fetcher<UserTxsResponse, UserTxsRequest>(
          `${BS_API_GATEWAY_URL}/solowallet/transactions`,
          "POST",
          {
            userId: user.id,
            pagination: {
              page: 0,
              size: 10,
            },
          },
        );

        setUserTransactions({ ...data });
      } catch (e) {
        console.log(e);
      }
    })();
  }, [user]);

  return (
    <>
      <Flex direction="column" flexGrow={1} basis="100%" gap="5" p="5" pb="20">
        <Flex
          p="5"
          gap="4"
          direction="row"
          flexWrap={{ base: "wrap", lg: "nowrap" }}
          align="center"
          justifyItems="center"
          justify="space-between"
        >
          <Flex
            direction="row"
            flexWrap={{ base: "wrap", lg: "nowrap" }}
            justify={{ base: "start", lg: "center" }}
            align={{ base: "start", lg: "center" }}
            gap={2}
          >
            <Heading size="md">PERSONAL SAVINGS</Heading>
            <Text>Save in bitcoin and grow your wealth</Text>
          </Flex>
          <Button
            variant="outline"
            colorScheme="green"
            height="35px"
            onClick={onOpenPersonalSavingsInfoModal}
          >
            about personal savings
            <Icon as={FaInfoCircle} ml="2" />
          </Button>
        </Flex>
        {memberStatus.hasShares ? (
          <Flex direction="column" my="12" gap={10}>
            {user && (
              <BalanceCard
                user={user}
                walletMeta={userTransactions?.meta}
                setUserTransactions={(
                  ledger?: PaginatedSolowalletTxsResponse,
                  meta?: WalletMeta,
                ) => setUserTransactions({ ledger, meta })}
              />
            )}
            <Flex direction="column">
              <Text mb="4">TRANSACTION HISTORY</Text>
              <Flex direction="column" gap="3">
                <Table variant={"striped"}>
                  <Thead>
                    <Tr>
                      <Th>Created At</Th>
                      <Th>Amount (msats)</Th>
                      <Th>Amount (KES)</Th>
                      <Th>Type</Th>
                      <Th>Status</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {paginatedTransactions?.map((tx) => (
                      <Tr key={tx.id}>
                        <Td>{tx.createdAt}</Td>
                        <Td>{tx.amountMsats}</Td>
                        <Td>{tx.amountFiat}</Td>
                        <Td>{getTxTypeBadge(tx.type)}</Td>
                        <Td>{getTxStatusBadge(tx.status)}</Td>
                        <Td>
                          <TableMenu
                            onCopyId={() => handleOptionSelect("Copy ID")}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Flex justify="space-between" mt="4">
                  <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    isDisabled={currentPage === 0}
                  >
                    Previous
                  </Button>
                  <Text>
                    Page {currentPage + 1} of {totalPages}
                  </Text>
                  <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    isDisabled={currentPage >= totalPages - 1}
                  >
                    Next
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <FeatureTease
            img={SavingsIcon}
            title="Welcome to Bitsacco Personal Savings!"
          />
        )}
      </Flex>
      <PersonalSavingsInfoModal
        isOpen={showPersonalSavingsInfoModal}
        onClose={() => onClosePersonalSavingsInfoModal()}
      />
    </>
  );
});

interface BalanceCardProps {
  user: User;
  walletMeta?: WalletMeta;
  setUserTransactions: (
    deposits?: PaginatedSolowalletTxsResponse,
    meta?: WalletMeta,
  ) => void;
}

const BalanceCard = React.memo(function BalanceCard({
  user,
  walletMeta,
  setUserTransactions,
}: BalanceCardProps): JSX.Element {
  const quote = useQuote();

  const {
    isOpen: showDepositModal,
    onOpen: onOpenDepositModal,
    onClose: onCloseDepositModal,
  } = useDisclosure();

  const {
    isOpen: showWithdrawModal,
    onOpen: onOpenWithdrawModal,
    onClose: onCloseWithdrawModal,
  } = useDisclosure();

  const txTarget: TransactionTarget = {
    target: {
      id: "bps",
      name: "Bitsacco Personal Savings",
    },
    user,
  };

  const showBalanceInSats = useCallback(() => {
    return (
      <Heading fontSize={{ base: "30px", md: "48px" }} fontWeight="bold">
        &#8383; {formatNumber((walletMeta?.currentBalance || 0) / 1000)} SATS
      </Heading>
    );
  }, [walletMeta]);

  const showBalanceInFiat = useCallback(() => {
    if (!quote) {
      return <></>;
    }

    const { amountFiat } = btcToFiat({
      amountMsats: walletMeta?.currentBalance || 0,
      fiatToBtcRate: Number(quote.rate),
    });
    return (
      <Text fontSize={{ base: "24px", lg: "30px" }} fontWeight="bold">
        &#61; {formatNumber(amountFiat)} KES
      </Text>
    );
  }, [walletMeta, quote]);

  return (
    <Flex
      display={"flex"}
      alignItems={"center"}
      justifyItems={"center"}
      justifyContent={"center"}
      w={"100%"}
    >
      <Card w={{ base: "600px", xl: "800px" }} h={"350px"}>
        <CardBody
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyItems={"center"}
          justifyContent={"center"}
          gap={3}
        >
          <Text>you have</Text>
          {showBalanceInSats()}
          {showBalanceInFiat()}
        </CardBody>
        <CardFooter
          display={"flex"}
          alignItems={"center"}
          justifyItems={"center"}
          justifyContent={"center"}
        >
          <ButtonGroup
            gap={"4"}
            spacing={{ base: "0", lg: "4" }}
            display={"flex"}
            flexDir={"row"}
            flexBasis={"100%"}
            flexWrap={{ base: "wrap", lg: "nowrap" }}
          >
            <Button
              variant="solid"
              colorScheme="green"
              onClick={onOpenDepositModal}
              height={"45px"}
              minW={"150px"}
              flexGrow={1}
            >
              Deposit Funds
            </Button>
            <Button
              variant="solid"
              colorScheme="teal"
              onClick={onOpenWithdrawModal}
              height={"45px"}
              minW={"150px"}
              flexGrow={1}
              isDisabled={true}
            >
              Withdraw Funds
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
      <PersonalSavingsDepositModal
        txTarget={txTarget}
        setUserTransactions={setUserTransactions}
        onClose={onCloseDepositModal}
        isOpen={showDepositModal}
      />
      <PersonalSavingsWithdrawModal
        txTarget={txTarget}
        setUserTransactions={setUserTransactions}
        onClose={onCloseWithdrawModal}
        isOpen={showWithdrawModal}
      />
    </Flex>
  );
});
