"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FXID = exports.SupportedCurrency = void 0;
exports.fiatToBtc = fiatToBtc;
exports.btcToFiat = btcToFiat;
var SupportedCurrency;
(function (SupportedCurrency) {
    SupportedCurrency["BTC"] = "BTC";
    SupportedCurrency["KES"] = "KES";
})(SupportedCurrency || (exports.SupportedCurrency = SupportedCurrency = {}));
exports.FXID = "kesbtc";
function fiatToBtc({ amountFiat, btcToFiatRate, }) {
    const amountBtc = amountFiat / btcToFiatRate;
    const amountSats = amountBtc * 100000000;
    const amountMsats = Math.floor(amountSats * 1000);
    return { amountBtc, amountSats, amountMsats };
}
function btcToFiat({ amountBtc, amountSats, amountMsats, fiatToBtcRate, }) {
    let btcAmount;
    if (amountBtc !== undefined) {
        btcAmount = amountBtc;
    }
    else if (amountSats !== undefined) {
        btcAmount = amountSats / 100000000;
    }
    else if (amountMsats !== undefined) {
        btcAmount = amountMsats / 100000000000;
    }
    else {
        throw new Error("One of amountBtc, amountSats, or amountMsats must be provided");
    }
    const amountFiat = btcAmount * fiatToBtcRate;
    return { amountFiat };
}
