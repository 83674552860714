import React, { useCallback } from "react";
import {
  VStack,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Button,
  Text,
} from "@chakra-ui/react";
import { User, RegisterUser } from "@bitsacco/types";
import {
  isValidPhone,
  isValidPin,
  digitizePhone,
  isValidNpub,
} from "../../utils";
import { PinStack } from "./PinStack";
import { useApi } from "../Providers";
import { AuthStackProps, AuthExperience } from "./types";

export const SignupStack = ({
  pin,
  phone,
  useNpub,
  npub,
  authError,
  setPin,
  setPhone,
  setNpub,
  setAuthError,
  setAuthExperience,
  setUseNpub,
}: AuthStackProps) => {
  const { bitsacco } = useApi();

  const signupUser = useCallback(() => {
    if (!useNpub && !isValidPhone(phone)) {
      setAuthError("invalid phone number");
      return;
    }

    if (useNpub && !isValidNpub(npub)) {
      setAuthError("invalid nostr identity");
      return;
    }

    if (!isValidPin(pin)) {
      setAuthError("pin error");
      return;
    }

    (async () => {
      try {
        const user = await bitsacco.request<User, RegisterUser>(
          "POST",
          "/user/register",
          {
            phone: digitizePhone(phone),
            pin,
            npub,
            profile: null,
          },
        );

        if (user) {
          setAuthError("");
          return;
        }

        throw "failed to register user";
      } catch (e) {
        setAuthError(`${e}`);
      }
    })();
  }, [bitsacco, phone, pin, npub, useNpub, setAuthError]);

  return (
    <VStack>
      <Card align="center" w={"400px"} px={"5"}>
        <CardHeader>
          <Text fontSize="xl" fontWeight="semibold">
            SIGNUP
          </Text>
        </CardHeader>
        <CardBody>
          <VStack spacing={4}>
            <PinStack
              {...{
                pin,
                phone,
                useNpub,
                npub,
                authError,
                setPin,
                setPhone,
                setNpub,
                setAuthError,
                signupUser,
              }}
            />
            {/* <Button variant="link" onClick={() => setUseNpub(!useNpub)}>
              use {useNpub ? "phone" : "nostr"}
            </Button> */}
          </VStack>
        </CardBody>
      </Card>
      <Flex gap="4">
        <Button
          variant="ghost"
          onClick={() => setAuthExperience(AuthExperience.Login)}
        >
          LOGIN
        </Button>
        <Button
          variant="ghost"
          onClick={() => setAuthExperience(AuthExperience.Recover)}
        >
          RECOVER
        </Button>
      </Flex>
    </VStack>
  );
};
