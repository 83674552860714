import React from "react";
import { Badge } from "@chakra-ui/react";

export const ComingSoon = React.memo(function ComingSoon() {
  return (
    <Badge colorScheme="teal" variant="outline" borderRadius={5}>
      Coming Soon
    </Badge>
  );
});
