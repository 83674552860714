import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import { TransactionType, TxPath } from "@bitsacco/types";

import {
  POLL_INTERVAL_MS,
  POLL_TIMEOUT_MS,
  TOAST_TIMEOUT_MS,
} from "../../configs";
import { isKenyanPhone } from "../../utils";
import { StateHelperText, TransactionState } from "../TransactionState";
import {
  ActiveTx,
  LightningTransaction,
  MpesaTransaction,
  TransactionTarget,
} from "../transactions";
import { AlertId, AlertStatus, AppAlert } from "../AppAlert";
import { TemplateModal } from "./TemplateModal";

export interface TransactionModalProps {
  isOpen: boolean;
  onClose: () => void;
  createTx: (path: TxPath, amountFiat: number) => Promise<ActiveTx>;
  findTx: (activeTx: ActiveTx) => Promise<ActiveTx>;
  txTarget: TransactionTarget;
  txType: TransactionType;
}

export const TransactionModal = React.memo(function TransactionModal({
  txTarget,
  txType,
  isOpen,
  onClose,
  createTx,
  findTx,
}: TransactionModalProps): JSX.Element {
  const [amount, setAmount] = useState<number>(0);
  const [activeTx, setActiveTx] = useState<ActiveTx | undefined>();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>();

  const [stateHelperText, setStateHelperText] = useState<StateHelperText>({
    value: "",
  });
  const [tabIndex, setTabIndex] = useState<number>(0);
  const theme = useTheme();
  const toast = useToast();

  const label = txType === TransactionType.DEPOSIT ? "Deposit" : "Withdraw";

  useEffect(() => {
    if (!activeTx) return;

    const startTime = Date.now();

    const pollTx = async () => {
      try {
        if (Date.now() - startTime >= POLL_TIMEOUT_MS) {
          if (activeTx.state === TransactionState.Pending) {
            setActiveTx({ ...activeTx, state: TransactionState.Processing });
            setStateHelperText({
              value: `${label} in progress. You can safely close this window.`,
            });
          }

          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          return;
        }

        if (
          activeTx.state === TransactionState.Failed ||
          activeTx.state === TransactionState.Complete
        ) {
          clearTimeout(timeoutId);
          return;
        }

        const updatedTx = await findTx(activeTx);
        setActiveTx(updatedTx);
        switch (updatedTx.state) {
          case TransactionState.Processing:
            setStateHelperText({
              value: `${label} in progress. Please wait...`,
            });
            break;
          case TransactionState.Complete:
            toast({
              title: "Success",
              description: `${label} completed successfully`,
              status: "success",
              duration: TOAST_TIMEOUT_MS,
              isClosable: true,
            });
            setStateHelperText({
              value: `${label} completed successfully`,
            });
            clearTimeout(timeoutId);
            return;
          case TransactionState.Failed:
            toast({
              title: "Error",
              description: `${label} failed. Please try again`,
              status: "error",
              duration: TOAST_TIMEOUT_MS,
              isClosable: true,
            });
            setStateHelperText({
              value: `${label} failed. Please try again`,
              isError: true,
            });
            clearTimeout(timeoutId);
            return;
          default:
            break;
        }
        setTimeoutId(setTimeout(pollTx, POLL_INTERVAL_MS));
      } catch (error) {
        console.error(error);
        setStateHelperText({
          value: "Failed to fetch transaction status",
          isError: true,
        });
      }
    };

    pollTx();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId, activeTx, findTx, setActiveTx, setStateHelperText]);

  const createTransaction = useCallback(
    (path: TxPath) => {
      if (activeTx || !amount) {
        return;
      }

      (async () => {
        try {
          const tx = await createTx(path, amount);
          setActiveTx(tx);

          toast({
            title: "Success",
            description: `${label} started. Please wait while we process your transaction`,
            status: "success",
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });
        } catch (_) {
          setStateHelperText({
            value: "Failed to create transaction",
            isError: true,
          });
        }
      })();
    },
    [amount, activeTx, setActiveTx],
  );

  const restartTransaction = useCallback(() => {
    setAmount(0);
    setActiveTx(undefined);
    setStateHelperText({ value: "" });
    clearTimeout(timeoutId);
  }, [timeoutId, setAmount, setActiveTx, setStateHelperText]);

  const closeDialogue = useCallback(() => {
    restartTransaction();
    onClose();
  }, [restartTransaction, onClose]);

  const getTransactionActions = useCallback(() => {
    if (
      activeTx?.state === TransactionState.Failed ||
      activeTx?.state === TransactionState.Processing ||
      activeTx?.state === TransactionState.Complete
    ) {
      return (
        <>
          <Button
            onClick={restartTransaction}
            variant="outline"
            colorScheme="green"
          >
            Start Over
          </Button>
          <Button onClick={closeDialogue} variant="outline" colorScheme="red">
            Close
          </Button>
        </>
      );
    }

    return (
      <Button onClick={closeDialogue} variant="outline" colorScheme="red">
        Cancel
      </Button>
    );
  }, [activeTx, restartTransaction, closeDialogue]);

  const getModalHeader = useCallback(() => {
    return <Text>{`${label} funds to ${txTarget.target.name}`}</Text>;
  }, [txTarget]);

  const getModalBody = useCallback(() => {
    const useMpesa = isKenyanPhone(txTarget.user.phone || "");

    return (
      <Flex direction="column" gap="2">
        <Tabs
          isFitted
          colorScheme={theme.colors.teal[50]}
          onChange={(index) => setTabIndex(index)}
          defaultIndex={tabIndex}
        >
          <TabList mb="1em">
            <Tab>Use Mpesa</Tab>
            <Tab>Use Lightning</Tab>
          </TabList>
          <TabPanels minH="16em">
            <TabPanel>
              <Flex
                flexDirection="column"
                gap="5"
                h="100%"
                justify="center"
                align="center"
              >
                {useMpesa ? (
                  <MpesaTransaction
                    amount={amount}
                    updateAmount={setAmount}
                    txTarget={txTarget}
                    activeTx={activeTx}
                    createTxButton={{
                      onClick: createTransaction,
                      label: `${label} Funds`,
                    }}
                    stateHelperText={stateHelperText}
                  />
                ) : (
                  <AppAlert
                    id={AlertId.Generic}
                    status={AlertStatus.Warning}
                    description={
                      <Text fontSize={{ base: "14px", md: "16px" }}>
                        <strong>Use Lightning! </strong>
                        Your account is not connected to a Kenyan phone number.
                        Please use Lightning to deposit funds.
                      </Text>
                    }
                  />
                )}
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex flexDirection="column" gap="5" h="100%" justify="center">
                <LightningTransaction
                  amount={amount}
                  updateAmount={setAmount}
                  txTarget={txTarget}
                  activeTx={activeTx}
                  createTxButton={{
                    onClick: createTransaction,
                    label: `${label} Funds`,
                  }}
                  stateHelperText={stateHelperText}
                />
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Text align="center" justifyContent="center">
          {`You can ${label.toLowerCase()} any amount between 1 and 1000 Kenya Shillings`}
        </Text>
      </Flex>
    );
  }, [
    amount,
    txTarget,
    stateHelperText,
    theme,
    tabIndex,
    setAmount,
    setTabIndex,
    createTransaction,
    activeTx,
  ]);

  const getModalFooter = useCallback(() => {
    return <ButtonGroup spacing="2">{getTransactionActions()}</ButtonGroup>;
  }, [getTransactionActions]);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={onClose}
      header={getModalHeader()}
      body={getModalBody()}
      footer={getModalFooter()}
    />
  );
});
