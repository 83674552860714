import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { FaEllipsisVertical } from "react-icons/fa6";

interface TableMenuProps {
  onCopyId: () => void;
}

export const TableMenu: React.FC<TableMenuProps> = ({ onCopyId }) => {
  return (
    <Menu isLazy>
      <MenuButton
        variant="ghost"
        as={IconButton}
        aria-label="Options"
        icon={<FaEllipsisVertical />}
        size="sm"
        ml={2}
      />
      <MenuList>
        <MenuItem onClick={onCopyId}>Copy ID</MenuItem>
      </MenuList>
    </Menu>
  );
};
