import React, { useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { TemplateModal } from "./TemplateModal";

interface MembershipInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const MembershipInfoModal = React.memo(function MembershipInfoModal({
  isOpen,
  onClose,
}: MembershipInfoModalProps): JSX.Element {
  const getModalBody = useCallback(() => {
    return (
      <Flex flexDirection="column" gap="5">
        <Text>
          Bitsacco members can purchase, hold and even transfer shares which
          represent ownership stake in the SACCO.
        </Text>
        <UnorderedList pl={4} spacing={3}>
          <ListItem>
            Each registered member must hold at least one (1) share
          </ListItem>
          <ListItem>Each share is valued at 1,000 KES.</ListItem>
          <ListItem>Shares can be transferred among members</ListItem>
          <ListItem>
            Each member can hold up to a maximum of (20%) of all issued shares
          </ListItem>
        </UnorderedList>
        <Text>
          We will share updates on the following critical membership features
        </Text>
        <UnorderedList pl={4} spacing={3}>
          <ListItem>Transferring shares among members</ListItem>
          <ListItem>
            Listing beneficiary / next of kin for subscribed shares
          </ListItem>
        </UnorderedList>
      </Flex>
    );
  }, []);

  return (
    <TemplateModal
      isOpen={isOpen}
      onClose={onClose}
      header={<Text>About Bitsacco Membership</Text>}
      body={getModalBody()}
      footer={
        <Button onClick={onClose} variant="outline" colorScheme="teal">
          I acknowledge and understand
        </Button>
      }
    />
  );
});
