import React from "react";
import { User } from "@bitsacco/types";
import {
  VStack,
  Card,
  CardHeader,
  CardBody,
  Flex,
  Skeleton,
  Button,
  Text,
} from "@chakra-ui/react";
import { getProfileLabel } from "../../utils";
import { Headshot } from "../Headshot";
import { useAuth } from "../Providers";
import { AuthExperience } from "./types";

interface ResumeStackProps {
  user: User;
  setAuthExperience: (exp: AuthExperience) => void;
}

export const ResumeStack = ({ user, setAuthExperience }: ResumeStackProps) => {
  const { login } = useAuth();

  return (
    <VStack>
      <Card align="center" w={"400px"} px={"5"}>
        <CardHeader>
          <Text fontSize="xl" fontWeight="semibold">
            Welcome Back
          </Text>
        </CardHeader>
        <CardBody>
          <Flex align="center" flexDir={"column"} gap={4}>
            <Skeleton size="lg" isLoaded={!!user}>
              <Headshot user={user} size="lg" />
            </Skeleton>
            <Text fontSize="xl">{getProfileLabel(user)}</Text>
            <Button
              variant="solid"
              colorScheme="teal"
              onClick={() => login(user)}
              w="100%"
            >
              Continue
            </Button>
          </Flex>
        </CardBody>
      </Card>
      <Flex gap="4">
        <Button
          variant="link"
          onClick={() => setAuthExperience(AuthExperience.Login)}
        >
          Login
        </Button>
        <Button
          variant="link"
          onClick={() => setAuthExperience(AuthExperience.Signup)}
        >
          Signup
        </Button>
      </Flex>
    </VStack>
  );
};
