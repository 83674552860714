import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  IconButton,
  Skeleton,
  Stack,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { UpdateUser, User } from "@bitsacco/types";
import {
  useAuth,
  Headshot,
  useApi,
  AccountNostr,
  AccountProfile,
  AccountSecurity,
  // AccountAddress,
  useAppAlerts,
  AppAlert,
  AlertId,
  AlertStatus,
} from "../components";
import { createCopyTextFn, getProfileLabel } from "../utils";
import { TOAST_TIMEOUT_MS } from "../configs";
import { Routes } from "../routes";

export const Account = React.memo(function Accoint() {
  const { bitsacco } = useApi();
  const { user, login, logout } = useAuth();

  const { colorMode } = useColorMode();

  const [edit, setEdit] = useState(false);
  const [updateError, setUpdateError] = useState<string>("");

  const [pname, setPname] = useState<string>(user?.profile?.name || "");
  const [paddress] = useState(user?.profile?.address || "");

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const { appAlerts, registerAppAlert } = useAppAlerts();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!user.profile) {
      registerAppAlert({
        id: AlertId.Generic,
        status: AlertStatus.Info,
        description: (
          <Flex
            direction="row"
            wrap={{ base: "wrap", md: "nowrap" }}
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Text fontSize={{ base: "14px", md: "16px" }}>
              <strong>Your can edit your profile... </strong>
              {`Having a profile with your name and other info helps your chama members easily
              recognize and interact with you. `}
              Press <strong>Edit</strong> button above to get started
            </Text>
          </Flex>
        ),
      });
    }
  }, [user, registerAppAlert]);

  const cancelEdit = useCallback(() => {
    setEdit(false);
  }, [setEdit]);

  const saveEdits = useCallback(() => {
    if (!user) {
      return;
    }

    const { name, address } = user.profile || {};

    if (name && name === pname && address && address === paddress) {
      return setEdit(false);
    }

    const profile = { ...user.profile, name: pname, address: paddress };

    (async () => {
      try {
        setLoading(true);
        const updated = await bitsacco.request<User, UpdateUser>(
          "PATCH",
          "/user/update",
          {
            id: user.id,
            updates: {
              pinHash: user.pinHash,
              profile,
            },
          },
        );

        if (updated) {
          setUpdateError("");
          setEdit(false);
          setLoading(false);

          toast({
            title: "Success",
            description: `Updated account info.`,
            status: "success",
            duration: TOAST_TIMEOUT_MS,
            isClosable: true,
          });

          return login(updated, Routes.Account);
        }

        setLoading(false);
        throw "failed to update account info";
      } catch (e) {
        setUpdateError(`${e}`);
        setLoading(false);
      }
    })();
  }, [bitsacco, user, pname, paddress, toast, login, setEdit, setUpdateError]);

  const bgBox = colorMode === "dark" ? "gray.800" : "";
  const bdBox = colorMode === "dark" ? "gray.800" : "";

  return (
    <Box bg={colorMode === "dark" ? "gray.800" : ""} minH="100vh" p={4}>
      {user && (
        <Stack spacing={6} maxW="xl" mx="auto" pt={8}>
          <Flex align="center">
            <Skeleton size="lg" isLoaded={!!user}>
              <Headshot user={user} size="lg" />
            </Skeleton>
            <Flex align="center" justify="center" gap={2} ml={4}>
              <Text fontSize="xl" fontWeight="bold">
                {getProfileLabel(user)}
              </Text>
              {!edit && (
                <IconButton
                  aria-label="Copy Profile Label"
                  icon={<CopyIcon />}
                  onClick={() =>
                    createCopyTextFn(toast)(
                      "profile label",
                      getProfileLabel(user),
                    )
                  }
                />
              )}
            </Flex>
          </Flex>
          <Flex justify="space-between">
            <Button colorScheme="teal" onClick={logout} variant="outline">
              Logout
            </Button>
            {edit ? (
              <Button colorScheme="teal" variant="outline" onClick={cancelEdit}>
                Cancel
              </Button>
            ) : (
              <Button colorScheme="teal" onClick={() => setEdit(true)}>
                Edit
              </Button>
            )}
          </Flex>

          {updateError && (
            <Text color="red.500" textAlign="center">
              {updateError}
            </Text>
          )}

          <Divider orientation="horizontal" borderWidth="2px" />

          <Box w={"100%"}>
            {appAlerts.map((alert, idx) => (
              <AppAlert {...alert} key={idx} />
            ))}
          </Box>

          <AccountProfile
            edit={edit}
            phone={user.phone}
            name={edit ? pname : user.profile?.name}
            proposeName={setPname}
            bgBox={bgBox}
            bdBox={bdBox}
          />

          {/* <AccountAddress
            edit={edit && !isValidAddress(user.profile?.address)}
            address={edit ? paddress : user.profile?.address}
            proposeAddress={setPaddress}
            copyAddress={() =>
              createCopyTextFn(toast)('address', user.profile?.address || '')
            }
            bgBox={bgBox}
            bdBox={bdBox}
          /> */}

          <AccountSecurity
            phone={user.phone}
            npub={user.npub}
            useNpub={!user.phone && !!user.npub}
            edit={edit}
          />

          <AccountNostr edit={edit} bgBox={bgBox} bdBox={bdBox} />

          {edit && (
            <>
              {loading ? (
                <CircularProgress isIndeterminate />
              ) : (
                <Button colorScheme="teal" onClick={saveEdits} disabled={false}>
                  Save
                </Button>
              )}
            </>
          )}

          <Divider orientation="horizontal" borderWidth="2px" />
        </Stack>
      )}
    </Box>
  );
});
