import React, { RefObject } from "react";
import {
  Button,
  Flex,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  FaUserLock,
  FaLock,
  FaCircleNotch,
  FaArrowRight,
  FaBlog,
} from "react-icons/fa6";
import { useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "../assets/svgs/logo.svg";
import { ReactComponent as LogoFull } from "../assets/svgs/logo_full.svg";
import { RouterLink } from "./RouterLink";
import { useAuth } from "./Providers";
import { Headshot } from "./Headshot";
import { BS_BLOG_URL } from "../configs";
import { Routes } from "../routes";

interface HeaderProps {
  menuRef: RefObject<HTMLDivElement>;
}

export const Header = React.memo(function Header({
  menuRef,
}: HeaderProps): JSX.Element {
  const { user, logout } = useAuth();
  const theme = useTheme();

  const location = useLocation();
  const [isSmallScreen] = useMediaQuery("(max-width: 767px)");

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      w={"100%"}
      maxW="1440px"
      px={{ base: "12px", md: "20px", lg: "40px", xl: "100px" }}
      position="sticky"
      top={"2px"}
      height={{ base: "64px", md: "100px" }}
      zIndex={1000}
    >
      <RouterLink to="/">
        <Icon
          as={isSmallScreen ? Logo : LogoFull}
          fill={theme.colors.teal[500]}
          height={{ base: "58px", md: "80px" }}
          width={"100%"}
        />
      </RouterLink>
      {isSmallScreen ? (
        <Menu isLazy>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={
              user ? (
                <Headshot
                  user={user}
                  size="md"
                  border={`2px solid ${theme.colors.teal[500]}`}
                />
              ) : (
                <HamburgerIcon height="50px" />
              )
            }
            variant="none"
          />
          <Portal containerRef={menuRef}>
            <MenuList w="100%">
              {user ? (
                <>
                  <RouterLink to={Routes.Account}>
                    <MenuItem icon={<Headshot user={user} size="xs" />}>
                      Account
                    </MenuItem>
                  </RouterLink>
                  <MenuDivider />
                  <RouterLink to={Routes.Membership}>
                    <MenuItem icon={<FaCircleNotch size="1.5em" />}>
                      Membership
                    </MenuItem>
                  </RouterLink>
                  <RouterLink to={Routes.Personal}>
                    <MenuItem icon={<FaLock size="1.5em" />}>Personal</MenuItem>
                  </RouterLink>
                  <RouterLink to="/chama">
                    <MenuItem icon={<FaCircleNotch size="1.5em" />}>
                      Chama
                    </MenuItem>
                  </RouterLink>
                  <MenuDivider />
                  {BS_BLOG_URL && (
                    <MenuItem icon={<FaBlog size="1.5em" />}>
                      <Link href={BS_BLOG_URL}>Blog</Link>
                    </MenuItem>
                  )}
                  <MenuDivider />
                  <MenuItem
                    icon={<FaArrowRight size="1.5em" />}
                    onClick={logout}
                  >
                    Logout
                  </MenuItem>
                </>
              ) : (
                <>
                  <RouterLink to="/auth">
                    <MenuItem icon={<FaUserLock size="1.5em" />}>
                      Login / Signup
                    </MenuItem>
                  </RouterLink>
                  <MenuDivider />
                  {BS_BLOG_URL && (
                    <MenuItem icon={<FaBlog size="1.5em" />}>
                      <Link href={BS_BLOG_URL}>Blog</Link>
                    </MenuItem>
                  )}
                </>
              )}
            </MenuList>
          </Portal>
        </Menu>
      ) : (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          fontFamily="body"
          gap={4}
        >
          {BS_BLOG_URL && (
            <Link
              href={BS_BLOG_URL}
              isExternal
              borderRadius={"8px"}
              fontWeight={"700"}
              fontSize={{ base: "18px" }}
              minW={"124px"}
              height={"54px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              _hover={{
                textDecor: "none",
                color: "teal",
              }}
            >
              BLOG
            </Link>
          )}
          {user ? (
            <>
              <RouterLink to="/chama">
                <Button
                  variant="ghost"
                  borderBottom={
                    location.pathname.startsWith("/chama")
                      ? `3px solid ${theme.colors.teal[500]}`
                      : "none"
                  }
                  colorScheme="teal"
                  minW={"124px"}
                  height={"54px"}
                >
                  CHAMA
                </Button>
              </RouterLink>
              <RouterLink to={Routes.Personal}>
                <Button
                  variant="ghost"
                  colorScheme="teal"
                  minW={"124px"}
                  height={"54px"}
                  borderBottom={
                    location.pathname.startsWith(Routes.Personal)
                      ? `3px solid ${theme.colors.teal[500]}`
                      : "none"
                  }
                >
                  PERSONAL
                </Button>
              </RouterLink>
              <RouterLink to={Routes.Membership}>
                <Button
                  variant="ghost"
                  borderBottom={
                    location.pathname.startsWith(Routes.Membership)
                      ? `3px solid ${theme.colors.teal[500]}`
                      : "none"
                  }
                  colorScheme="teal"
                  minW={"124px"}
                  height={"54px"}
                >
                  MEMBERSHIP
                </Button>
              </RouterLink>
              <Menu isLazy>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={
                    <Headshot
                      user={user}
                      size="md"
                      border={
                        location.pathname.startsWith(Routes.Account)
                          ? `3px solid ${theme.colors.teal[500]}`
                          : "none"
                      }
                    />
                  }
                  variant="none"
                />
                <MenuList w="100%" zIndex={100}>
                  <RouterLink to={Routes.Account}>
                    <MenuItem icon={<Headshot user={user} size="xs" />}>
                      Account
                    </MenuItem>
                  </RouterLink>
                  <MenuDivider />
                  <MenuItem
                    icon={<FaArrowRight size="1.5em" />}
                    onClick={logout}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          ) : (
            <RouterLink to="/auth">
              <Button
                variant={"solid"}
                bgColor={"teal"}
                minW={"124px"}
                borderRadius={"8px"}
                height={"54px"}
                fontWeight={"700"}
                fontSize={{ base: "18px" }}
                border="2px solid teal"
                _hover={{
                  textDecor: "none",
                  bgColor: "white",
                  color: "teal",
                  border: "2px solid teal",
                }}
              >
                LOGIN / SIGNUP
              </Button>
            </RouterLink>
          )}
        </Flex>
      )}
    </Flex>
  );
});
