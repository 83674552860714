import React from "react";
import { useDisclosure, Heading, Flex, Button } from "@chakra-ui/react";
import { ResetPinModal } from "../modal";

interface AccountSecurityProps {
  phone: string | null;
  npub: string | null;
  useNpub: boolean;
  edit: boolean;
}

export const AccountSecurity = React.memo(function AccountSecurity({
  phone,
  useNpub,
  npub,
  edit,
}: AccountSecurityProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Heading as="h2" size="md">
        Security
      </Heading>

      <Flex>
        <Button
          colorScheme="teal"
          variant="outline"
          onClick={() => {
            !edit && onOpen();
          }}
          isDisabled={edit && !phone && !npub}
        >
          Reset password
        </Button>
      </Flex>

      <ResetPinModal
        isOpen={isOpen}
        onClose={onClose}
        phone={phone || ""}
        useNpub={useNpub}
        npub={npub || ""}
      />
    </>
  );
});
