import React from "react";
import {
  Button,
  Flex,
  Text,
  useTheme,
  useColorModeValue,
} from "@chakra-ui/react";

import { FAQ, FeaturesSection, RouterLink, useAuth } from "../components";

export const Hero = React.memo(function Hero(): JSX.Element {
  const theme = useTheme();
  const { user } = useAuth();

  const bg = useColorModeValue(theme.colors.gray[100], theme.colors.gray[500]);
  return (
    <Flex direction="column" w={"100%"}>
      <Flex
        direction="column"
        w={"100%"}
        minH={{
          base: "calc(100vh - 64px)",
          sm: "calc(100vh - 64px)",
          md: "calc(100vh - 100px)",
        }}
        justifyContent={"center"}
        gap={"16"}
      >
        <Flex
          direction="column"
          justifyContent="center"
          alignItems={"center"}
          height={"100%"}
        >
          <Flex
            justifyContent="center"
            alignItems={"center"}
            direction={{ base: "column", md: "row" }}
            gap={{ base: 2, md: 4 }}
            pl={{ base: "", md: "10" }}
          >
            <Text
              fontSize={{ base: "40px", md: "56px", lg: "72px", xl: "86px" }}
              fontWeight={"600"}
              textAlign={"center"}
              fontFamily={"body"}
              lineHeight={"140%"}
            >
              PLAN
            </Text>

            <Text
              fontSize={{ base: "40px", md: "56px", lg: "72px", xl: "86px" }}
              fontWeight={"600"}
              textAlign={"center"}
              fontFamily={"body"}
              lineHeight={"140%"}
            >
              <span>&#183;</span>
            </Text>

            <Text
              fontSize={{ base: "40px", md: "56px", lg: "72px", xl: "86px" }}
              fontWeight={"600"}
              textAlign={"center"}
              fontFamily={"body"}
              lineHeight={"140%"}
            >
              <span style={{ color: "teal" }}> SAVE</span>
            </Text>

            <Text
              fontSize={{ base: "40px", md: "56px", lg: "72px", xl: "86px" }}
              fontWeight={"600"}
              textAlign={"center"}
              fontFamily={"body"}
              lineHeight={"140%"}
            >
              <span>&#183;</span>
            </Text>

            <Text
              fontSize={{ base: "40px", md: "56px", lg: "72px", xl: "86px" }}
              fontWeight={"600"}
              textAlign={"center"}
              fontFamily={"body"}
              lineHeight={"140%"}
            >
              GROW
            </Text>
          </Flex>

          <Text
            bg={bg}
            p={"6px 16px"}
            borderRadius={"32px"}
            fontWeight={"500"}
            fontFamily={"body"}
            fontSize={{ base: "13px", lg: "14px" }}
            mt={12}
          >
            USING BITCOIN
          </Text>

          <Text
            textAlign={"center"}
            maxW={"720px"}
            mt={12}
            fontFamily={"body"}
            fontSize={{ base: "18px", lg: "24px" }}
          >
            Plan your finances. Save towards targets. Grow your finances
            together with community, friends and family.
          </Text>

          {!user && (
            <RouterLink to="/auth">
              <Button
                mt="10"
                variant={"solid"}
                bgColor={"teal"}
                minW={"200px"}
                borderRadius={"8px"}
                height={"54px"}
                fontWeight={"700"}
                fontSize={{ base: "18px", lg: "24px" }}
                _hover={{
                  textDecor: "none",
                  bgColor: "white",
                  color: "teal",
                  border: "2px solid teal",
                }}
              >
                LOGIN / SIGNUP
              </Button>
            </RouterLink>
          )}
        </Flex>
      </Flex>
      <FeaturesSection />
      {/* <Blog /> */}
      {/* {user && <AccessFedi />} */}
      <FAQ />
    </Flex>
  );
});
