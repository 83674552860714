import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  FormHelperText,
  FormControl,
  Text,
} from "@chakra-ui/react";
import { TxPath } from "@bitsacco/types";

import { AmountInputGroup, PhoneInputGroup } from "../InputGroups";
import { TransactionState, TransactionStateTracker } from "../TransactionState";
import { TransactionUxProps } from "./types";

export const MpesaTransaction = React.memo(function MpesaTransaction({
  amount,
  txTarget,
  stateHelperText,
  activeTx,
  updateAmount,
  createTxButton,
}: TransactionUxProps) {
  const [phone, setPhone] = useState<string>(txTarget.user.phone!);

  const getFormHelperText = useCallback(() => {
    if (stateHelperText.isError) {
      return (
        <FormHelperText color="red.300">{stateHelperText.value}</FormHelperText>
      );
    }

    return <FormHelperText>{stateHelperText.value}</FormHelperText>;
  }, [stateHelperText]);

  if (activeTx?.path === TxPath.Lightning) {
    return (
      <Text>
        Lightning transaction in progress... please switch to the Lightning tab
      </Text>
    );
  }

  const depositState = activeTx?.state || TransactionState.Create;

  if (depositState === TransactionState.Create) {
    return (
      <Flex flexDirection="column" gap="5" h="100%" justify="center">
        <FormControl>
          <Box pb="5">
            <AmountInputGroup
              amount={amount}
              setAmount={(amnt) => {
                if (amnt > 1000) {
                  return;
                }
                updateAmount(amnt);
              }}
              getFormHelperText={(amountError?: string) => {
                return amountError ? (
                  <FormHelperText color="red.300">{amountError}</FormHelperText>
                ) : (
                  <></>
                );
              }}
            />
          </Box>
          <Box pb="2">
            <PhoneInputGroup phone={phone} setPhone={setPhone} />
          </Box>
          {getFormHelperText()}
        </FormControl>
        <Button
          onClick={() => createTxButton.onClick(TxPath.Mpesa)}
          variant="solid"
          colorScheme="green"
        >
          {createTxButton.label}
        </Button>
      </Flex>
    );
  }

  return (
    <TransactionStateTracker
      transactionState={depositState}
      stateHelperText={stateHelperText}
      progress={{
        isIndeterminate: true,
        thickness: "4px",
        color: "green.300",
        size: "3em",
      }}
      icon={{ boxSize: "4em" }}
    />
  );
});
